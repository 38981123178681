import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../utils/filters";
import Policy, { PRIVACY } from "../../components/Policy";
import React from "react";

const PrivacyPolicyPage = (props) => {
    const version = getQueryParams("version", props.location.search);

    return <Policy policyType={PRIVACY} version={version} />;
};

export default withRouter(PrivacyPolicyPage);

// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import CardSecondary from "../../UI/Card/CardSecondary";
import ScheduleCall from "./ScheduleCall";
import ProgramDetails from "./ProgramDetails";
import GetNotified from "./GetNotified";
import ReleaseOfInformation from "./ReleaseOfInformation";
import TeenChildSignup from "./TeenChildSignup";
import { isNil } from "lodash-es";
import CompleteChildWorkflow from "./CompleteChildWorkflow";

export const programDetails = "program-details";
export const consentToTerms = "consent-to-terms";
export const releaseForm = "release-form";
export const signup = "signup";
export const scheduleCall = "schedule-call";
export const getNotified = "get-notified";
export const complete = "complete";

const TeensAndKidsCareProgram = (props) => {
    const [activeStep, setActiveStep] = useState(programDetails);
    const [providerMetaData, setProviderMetaData] = useState(null);
    const [patientMetaData, setPatientMetaData] = useState(null);

    const changeWorkFlowStep = (activeStep, searchParams = null) => {
        window.scrollTo(0, 0);
        setActiveStep(activeStep);

        if (isNil(searchParams)) {
            props.history.push(`/app/teens-and-kids/${activeStep}`);
        } else {
            props.history.push(`/app/teens-and-kids/${activeStep}?${searchParams}`);
        }
    };

    const sharedProps = {
        providerMetaData,
        setProviderMetaData,
        patientMetaData,
        setPatientMetaData,
    };

    const workflowSteps = {
        "program-details": (
            <ProgramDetails
                nextStep={() => changeWorkFlowStep(signup)}
                getNotifiedStep={() => changeWorkFlowStep(getNotified)}
            />
        ),
        signup: <TeenChildSignup {...sharedProps} changeWorkFlowStep={changeWorkFlowStep} />,
        "schedule-call": (
            <ScheduleCall
                patientMetaData={patientMetaData}
                nextStep={() =>
                    changeWorkFlowStep(complete, `patientId=${patientMetaData.patient_id}`)
                }
                alreadyScheduled={(patientId) =>
                    changeWorkFlowStep(complete, `patientId=${patientId}`)
                }
            />
        ),
        "release-form": (
            <ReleaseOfInformation
                patientMetaData={patientMetaData}
                providerMetaData={providerMetaData}
                nextStep={() =>
                    changeWorkFlowStep(scheduleCall, `patientId=${patientMetaData.patient_id}`)
                }
            />
        ),
        complete: (
            <CompleteChildWorkflow
                patientMetaData={patientMetaData}
                nextStep={() => {
                    window.localStorage.removeItem("redirectAfterAuth");
                    props.history.push("/app/home");
                }}
            />
        ),
        "get-notified": <GetNotified {...props} />,
    };

    const isFirstPageLoad = () => !props.match.params.step;

    const isValidStep = () =>
        props.match.params.step && workflowSteps.hasOwnProperty(props.match.params.step);

    const isValidPath = () => props.match.path && props.match.path.includes("/app/teens-and-kids");

    useEffect(() => {
        (async () => {
            if (isFirstPageLoad() || (isValidPath() && !isValidStep())) {
                props.history.push(`/app/teens-and-kids/${programDetails}`);
            } else if (isValidPath() && isValidStep()) {
                setActiveStep(props.match.params.step);
            } else {
                props.history.push("/app/home");
            }
        })();
    }, [props.match.params.step]);

    return (
        <>
            <div className={"container px-3"}>
                <div className={"pt-3 pb-2 fw-bold fs-20 txt-gry"}>Add Child</div>
                <CardSecondary style={{ maxWidth: "unset" }}>
                    {activeStep && workflowSteps[activeStep]}
                </CardSecondary>
            </div>
        </>
    );
};

export default withRouter(TeensAndKidsCareProgram);

import React from "react";
import Input from "../../components/UI/Input";
import "./styles.scss";
import { createClassNameString } from "../utils";

export const ConsentEmail = ({ consentEvent, children, className, type, color, ...props }) => (
    <label className={createClassNameString(["ConsentEmail--container", className])}>
        <Input
            className="ConsentEmail--checkbox"
            type={"checkbox"}
            onClick={() => consentEvent()}
        />
        <span className="ConsentToTreatment--text">
            I consent to receive marketing and reminder emails from aptihealth. I understand that my
            consent to such emails is not required to use aptihealth’s services and that I may
            revoke my consent at any time.
        </span>
    </label>
);

ConsentEmail.defaultProps = {
    consentEvent: () => {},
};

import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../utils/filters";
import Policy, { CONSENT_TO_TREATMENT } from "../../components/Policy";
import React from "react";

const ConsentToTreatmentPolicyPage = (props) => {
    const version = getQueryParams("version", props.location.search);

    return <Policy policyType={CONSENT_TO_TREATMENT} version={version} />;
};

export default withRouter(ConsentToTreatmentPolicyPage);

import React from "react";
import Input from "../../components/UI/Input";
import "./styles.scss";
import { createClassNameString } from "../utils";
import { getAutomationDataAttr } from "../../utils/automationUtils";

export const ConsentToTreatment = ({ consentEvent, className, version }) => (
    <label
        className={createClassNameString(["ConsentToTreatment--container", className])}
        {...getAutomationDataAttr("consent-to-treatment-checkbox")}>
        <Input
            className="ConsentToTreatment--checkbox"
            type={"checkbox"}
            onClick={() => consentEvent()}
        />
        <span className={"ConsentToTreatment--text"}>
            I acknowledge that I have read and agreed to aptihealth’s{" "}
            <a
                className={"apti-green-v2 fs-15 bold text-decoration-underline"}
                href={`/consent?version=${version}`}
                target="_blank"
                rel="noreferrer">
                Consent to Treatment.
            </a>
        </span>
    </label>
);

ConsentToTreatment.defaultProps = {
    consentEvent: () => {},
};

// Copyright aptihealth, inc. 2022 All Rights Reserved
import React from "react";
import CloseOnOutsideClick from "../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { Link, LinkTypes, Modal } from "../../../component-library";
import { CloseIcon } from "../../Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";
import "./styles.scss";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const SuicidePreventionPopUp = (props) => {
    const { onCloseHandler } = props;
    return (
        <Modal
            cardClassName="suicide-prevention-pop-up"
            show={true}
            {...getAutomationDataAttr("suicide-prevention-pop-up")}>
            <CloseOnOutsideClick setShowComponent={onCloseHandler}>
                <div className={"d-flex justify-content-end"}>
                    <CloseIcon
                        onClick={onCloseHandler}
                        className={"suicide-prevention-pop-up--dismiss_icon"}
                        iconSize={ICON_SIZES.SMALL}
                        iconType={ICON_TYPES.OUTLINE}
                        {...getAutomationDataAttr("suicide-prevention-pop-up-close-button")}
                    />
                </div>
                <div className={"suicide-prevention-pop-up--inner text-align-center"}>
                    <div className={"fs-sm-20 fs-18 fw-bold mb-2"}>
                        Your responses indicate a potential urgent safety concern.
                    </div>
                    <div className={"mb-2"}>
                        {
                            "Someone from the aptihealth clinical team will reach out to you in the next 24 hours."
                        }
                    </div>
                    <div className={"mb-2"}>
                        {
                            "If you or someone else is in immediate, life-threatening danger, please call "
                        }
                        <Link type={LinkTypes.linkV2} href="tel:911">
                            911
                        </Link>
                        .
                    </div>
                    <div className={"mb-2"}>
                        {"For emotional support, call or text the "}
                        <Link type={LinkTypes.linkV2} href="https://988lifeline.org/">
                            Suicide and Crisis Lifeline
                        </Link>
                        {" at "}
                        <Link type={LinkTypes.linkV2} href="tel:988">
                            988
                        </Link>
                        {" or go to the nearest emergency room."}
                    </div>
                    <div className={"mb-3"}>You’re not alone, and help is available right now.</div>
                </div>
            </CloseOnOutsideClick>
        </Modal>
    );
};

export default SuicidePreventionPopUp;

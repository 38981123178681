import React from "react";
import { FormFieldContainer } from "../../../../../component-library/FormFields/FormFieldContainer/FormFieldContainer";
import { Text } from "../../../../../component-library";
import { FormTextInput } from "../../../../../component-library/FormFields/FormTextInput/FormTextInput";
import _ from "lodash";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const AdvancedCareActivityTextInput = ({
    readOnly,
    name,
    formikProps,
    testId,
    ...props
}) => {
    const value = _.get(formikProps.values, name);

    return (
        <>
            {!readOnly && (
                <FormTextInput name={name} formikProps={formikProps} {...props} testId={testId} />
            )}

            {readOnly && (
                <FormFieldContainer {...props}>
                    <Text className="AdvancedCareActivityForm-text_area_read_only" testId={testId}>
                        {value}
                    </Text>
                </FormFieldContainer>
            )}
        </>
    );
};

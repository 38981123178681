// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import AvailableSchedulesList from "../../../components/Patient/AvailableSchedulesList";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import { api } from "../../../APIRequests";
import { connect } from "react-redux";
import {
    ErrorCard,
    Link,
    PageBanner,
    Text,
    TextColors,
    TextTypes,
    PatientPaymentBanner,
} from "../../../component-library";
import moment from "moment-timezone";
import { validateAutomatedTestingFeatureFlag } from "../../../utils/featureFlag";
import { isCareTeamRestricted } from "../../../utils/scheduleUtils";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

class SchedulesAvailable extends Component {
    state = {
        scheduleList: null,
        isAddNewDisabled: true,
        scheduleLoaded: false,
    };

    deleteSchedule = (callId, data) => {
        /**
         * api call below returnx promise,
         * so we return that promise again to take decision in the concerned component.
         */
        return api.shared.remove_appointment({
            queryParams: { callId },
            data,
        });
    };

    fetchScheduleList = async () => {
        const response = await api.schedule.get_schedule({
            options: {
                params: {
                    showLoader: false,
                },
                queryParams: {
                    local_start_date_time: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
                    timezone: moment.tz.guess(),
                },
            },
        });
        const scheduleList = response.schedule;
        let isAddNewDisabled = true;
        if (this.props.profile && this.props.configs) {
            if (
                validateAutomatedTestingFeatureFlag(
                    this.props.configs.OVERRIDE_SCHEDULE_NEW_APPOINTMENT,
                    this.props.profile.email,
                )
            ) {
                isAddNewDisabled = false;
            } else {
                const { assessment_status, initial_video_consult, assessment_config } =
                    this.props.profile;
                const { skip_a5_for_referral } = assessment_config || {};
                isAddNewDisabled =
                    !assessment_status ||
                    (assessment_status && !initial_video_consult && scheduleList.length > 0);

                if (skip_a5_for_referral && !initial_video_consult && scheduleList.length === 0) {
                    isAddNewDisabled = false;
                }
            }
        }

        if (scheduleList) {
            this.setState({
                scheduleList,
                isAddNewDisabled,
                scheduleLoaded: true,
            });
        }
    };

    componentDidMount() {
        const dynamicRouteConfig = {};
        dynamicRouteConfig["logoSection"] = { title: "Schedule" };
        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);
        this.fetchScheduleList();
    }

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    getIsAddNewDisabled() {
        const canAddNew = this.state.isAddNewDisabled;
        const { is_wait_listed } = this.props?.profile || {};
        const isWaitlisted =
            is_wait_listed &&
            this.props.profile?.care_team_members?.reduce((prevVal, member) => {
                return prevVal && isCareTeamRestricted(member["type"], this.props.profile);
            }, true);

        return canAddNew || isWaitlisted;
    }

    isSchedulingRestricted() {
        return this.props.profile?.is_scheduling_restricted;
    }

    render() {
        const { patientCardInfo, flags } = this.props;
        const { showPaywall } = patientCardInfo ?? {};

        if (this.props.profile.status === "INACTIVE") {
            this.props.history.push("/app/schedule/inactive");
        }

        return (
            <div data-public="true">
                {showPaywall && (
                    <PatientPaymentBanner className={"PatientPaymentBanner-schedule"} />
                )}
                {this.isSchedulingRestricted() && !showPaywall && (
                    <PageBanner
                        className={"SchedulingRestrictedBanner d-flex flex-column margin-center"}
                        {...getAutomationDataAttr("member-scheduling-scheduling-restricted")}>
                        <Text
                            className={"align-self-baseline"}
                            type={TextTypes.label}
                            color={TextColors.black}>
                            Your scheduling options have changed
                        </Text>
                        <Text
                            className={"align-self-baseline"}
                            type={TextTypes.paragraph}
                            color={TextColors.black}>
                            To schedule/reschedule therapy sessions, contact your provider. To
                            schedule/reschedule medication management sessions, contact{" "}
                            <Link href="mailto:support@aptihealth.com" className={"apti-green-v2"}>
                                support@aptihealth.com
                            </Link>
                        </Text>
                    </PageBanner>
                )}

                <AvailableSchedulesList
                    fetchUpdatedSchedules={this.fetchScheduleList}
                    schedules={this.state.scheduleList}
                    deleteShedule={this.deleteSchedule}
                    isAddNewDisabled={this.getIsAddNewDisabled() || this.isSchedulingRestricted()}
                    scheduleLoaded={this.state.scheduleLoaded}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        configs: state.configs,
        patientCardInfo: state.patientCardInfo,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, { setDynamicRouteConfiguration })(SchedulesAvailable);

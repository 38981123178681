import React, { useEffect } from "react";
import images from "../../utils/images";
import Button from "../../components/UI/Button";
import FormikInput from "../../components/UI/FormikInput";
import { Form } from "formik";
import * as yup from "yup";
import * as Yup from "yup";
import { yup_password_factory } from "../../utils/yupSchemaUtils";
import { cloneDeep as _cloneDeep } from "lodash";
import { getLocalTimezone } from "../../redux/reducers/authReducer";
import { connect } from "react-redux";
import { completeAutoSignUp } from "../../redux/actions/auth";
import { withRouter } from "react-router-dom";
import { CustomForm, Footer } from "../../component-library";
import { tagReferralPath } from "../../utils/logRocketUtils";
import { getAutomationDataAttr } from "../../utils/automationUtils";

const ChildSignUp = ({ referralInfo, ...props }) => {
    useEffect(() => {
        tagReferralPath("teens_kids_program");
    }, []);

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        return (
            <Form>
                <div className="mt-5">
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "email",
                            elementType: "input",
                            elementConfig: {
                                label: "Email",
                                placeholder: "Enter Email",
                            },
                        }}
                        testId={"child-sign-up-email-input"}
                    />
                </div>
                <p className="mt-2">Create Your Password</p>
                <div className={"d-flex"}>
                    <div className={"mr-5 justify-content-between-input w-100"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "password",
                                elementType: "input",
                                elementConfig: {
                                    type: "viewablePassword",
                                    label: "Create Password",
                                    placeholder: "Enter Password",
                                },
                            }}
                            testId={"child-sign-up-password-input"}
                        />
                    </div>
                    <div className={"ml-5 justify-content-between-input w-100"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "confirm_password",
                                elementType: "input",
                                elementConfig: {
                                    type: "viewablePassword",
                                    label: "Confirm Password",
                                    placeholder: "Re-enter Password",
                                },
                            }}
                            testId={"child-sign-up-confirm-password-input"}
                        />
                    </div>
                </div>
                <div className={"d-flex justify-content-center mb-5"} style={{ width: "100%" }}>
                    <Button
                        type="submit"
                        className="Btn Btn--pri mt-4"
                        {...getAutomationDataAttr("child-sign-up-submit-button")}>
                        Continue
                    </Button>
                </div>
            </Form>
        );
    };

    const onSubmit = (formData) => {
        const data = _cloneDeep(formData);
        data["preferences"] = {
            time_zone: getLocalTimezone(),
        };
        data["code"] = referralInfo.referralCode;
        props.completeAutoSignUp(data);
    };

    return (
        <div>
            <img className="img-fluid" src={images("./consent-header.png")} />
            <div className={"mt-5 fs-24 fw-bold text-center"}>Welcome to aptihealth!</div>
            <div className={"fs-18 text-center txt-gry"}>
                Let’s get started by creating your account
            </div>
            <div className={"mb-5 top-body body-padding"}>
                <div className={"pl-4 pr-4"}>
                    <CustomForm
                        initialValues={{
                            email: "",
                            password: "",
                            confirm_password: "",
                        }}
                        validationSchema={passwordValidation}
                        onSubmit={onSubmit}
                        render={renderForm}
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default connect(null, { completeAutoSignUp })(withRouter(ChildSignUp));

const passwordValidation = yup.object().shape({
    email: Yup.string().required("Email is required").email("Enter valid email"),
    password: yup_password_factory(),
    confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Password does not match"),
});

import { getUsernameFromToken } from "../redux/actions/auth";
import moment from "moment";
import { getClientIpAddress } from "./ipUtil";
import { OUT_OF_NETWORK_INSURANCE_OPTION, UNINSURED_OPTION } from "../constants/insurnaceIconMap";
import { trackEvent } from "./EventTrackingUtil";

export const UNINSURED_POLICY_NAME = "uninsured_policy";
export const OUT_OF_NETWORK_INSURANCE_POLICY_NAME = "out_of_network_insurance_policy";
export const CANCELLATION_POLICY_NAME = "cancellation_policy";
export const CREDIT_CARD_AUTHORIZATION_POLICY_NAME = "credit_card_authorization_policy";

export const CARRIER_TO_INSURANCE_POLICY_NAME = {
    [UNINSURED_OPTION]: UNINSURED_POLICY_NAME,
    [OUT_OF_NETWORK_INSURANCE_OPTION]: OUT_OF_NETWORK_INSURANCE_POLICY_NAME,
};

/**
 * @deprecated This function is only asynchronous due to fetching of client IP, which we no longer
 * do, see https://aptihealth.atlassian.net/issues/AT1-6695. Use createAcknowledgementV2 instead
 */
export const createAcknowledgement = async () => {
    const clientIp = await getClientIpAddress();
    return {
        date: moment.utc().toISOString(),
        client: "web",
        acknowledged_by: getUsernameFromToken() || "self",
        client_ip: clientIp,
        timezone: moment.tz.guess(),
    };
};

export const createAcknowledgementV2 = ({ policyVersion, onBehalfOfAcknowledgement } = {}) => {
    const acknowledgedBy = getUsernameFromToken() || "self";
    const acknowledgement = {
        date: moment.utc().toISOString(),
        client: "web",
        acknowledged_by: acknowledgedBy,
        timezone: moment.tz.guess(),
    };

    if (policyVersion) {
        acknowledgement.version = policyVersion;
    }
    if (onBehalfOfAcknowledgement) {
        acknowledgement.consent_given_by = getUsernameFromToken();
    }

    return acknowledgement;
};

export const createInsurancePolicyAcknowledgement = async (insuranceCarrier) => {
    const policyName = CARRIER_TO_INSURANCE_POLICY_NAME[insuranceCarrier];
    return {
        [policyName]: await createAcknowledgement(),
    };
};

export const trackPolicyAcknowledgement = (policyName) => {
    trackEvent("Policy acknowledged", { policyName });
};

export const trackInsurancePolicyAcknowledgement = (carrierName) => {
    const policyName = CARRIER_TO_INSURANCE_POLICY_NAME[carrierName];
    trackPolicyAcknowledgement(policyName);
};

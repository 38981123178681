import React, { useEffect, useState } from "react";
import {
    BASIC_ADDRESS_SCHEMA,
    BASIC_CONTACT_INFO_SCHEMA,
    BASIC_EMERGENCY_CONTACT_SCHEMA,
    CHILD_ADDRESS_AUTOFILL,
    CHILD_SMS_CONSENT,
    DEFAULT_CHILD_PRACTICE_AUTOFILL,
} from "../../../formSchemas/newPatient";
import { Form } from "formik";
import Button from "../../UI/Button";
import { api } from "../../../APIRequests";
import { connect, useSelector } from "react-redux";
import { INITIAL_VALUES_V2 } from "../../../containers/Dashboard/Providers/schema/newPatientFormSchema";
import InsuranceForm from "../../Common/FormHelpers/InsuranceForm";
import _ from "lodash";
import { addNewPatient } from "../../../redux/actions/provider";
import FormikInputFormat from "../../UI/formikMobileInput";
import { formatMobileNumber } from "../../../utils/filters";
import FormikInput from "../../UI/FormikInput";
import moment from "moment-timezone";
import { CHILD_VALIDATION_SCHEMA } from "../../../containers/Dashboard/Providers/schema/newPatientValidation";
import { withRouter } from "react-router-dom";
import { releaseForm } from "./index";
import { trackEvent } from "../../../utils/EventTrackingUtil";
import { fetchInsuranceList } from "../../../redux/actions/insurance";
import { bindActionCreators } from "redux";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../constants/insurnaceIconMap";
import {
    createAcknowledgementV2,
    createInsurancePolicyAcknowledgement,
    trackInsurancePolicyAcknowledgement,
} from "../../../utils/userAgreementUtil";
import InsuranceEligibilityWrapper from "../../Common/InsuranceEligibility/InsuranceEligibilityWrapper";
import { CustomForm } from "../../../component-library";
import { featureFlagFunction } from "../../../utils/featureFlag";
import { OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG } from "../../../constants/featureFlags";
import { ConsentDisclaimer, createImplicitConsentV2 } from "../../Common/ConsentDisclaimer";
import publicRequests from "../../../APIRequests/public";
import { CONSENT_TO_TREATMENT } from "../../Policy";
import { ConsentToTreatment } from "../../../component-library/ConsentToTreatment";

const CHECKBOX_MAP = {
    child_address_autofill: false,
    child_sms_consent: false,
    default_child_practice_autofill: false,
};

const TeenChildSignup = (props) => {
    const [formCheckboxStates, setFormCheckboxStates] = useState(CHECKBOX_MAP);

    const [pcp, setPcp] = useState({});
    const [insuranceCompaniesList, setInsuranceCompaniesList] = useState([]);
    const [insuranceEligibilitySubmissionAttempts, setInsuranceEligibilitySubmissionAttempts] =
        useState(0);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showAcknowledgementPopup, setShowAcknowledgementPopup] = useState(false);

    const [insuranceDetails, setInsuranceDetails] = useState();
    const [accountData, setAccountData] = useState();
    const [policyMetadata, setPolicyMetadata] = useState({});
    const [consent, setConsent] = useState({});

    const name = `${props.profile.first_name} ${props.profile.last_name}`;
    const initialValues = {
        ...INITIAL_VALUES_V2,
        emergency_name: name,
        emergency_contact_no: props.profile.phone_number
            ? props.profile.phone_number
            : props.profile.mobile,
        guardian_id: props.profile.username,
        pcp_id: props.profile.pcp_id,
    };

    initialValues.insurance.policy_holder_name = name;
    initialValues.insurance.policy_relationship = "Other";

    const publicFlags = useSelector((state) => state.flags.publicFlags);
    const publicFlagsHaveLoaded = useSelector((state) => state.flags.publicFlagsHaveLoaded);

    const PARENT_PATIENT_INFO = {
        "Referring Person": pcp.name,
        Organization: pcp.organization,
        "Address, City State, Zip": pcp.address
            ? `${pcp.address.street}, ${pcp.address.city} ${pcp.address.state}, ${pcp.address.zip_code}`
            : "",
        Phone: formatMobileNumber(pcp.phone_number),
        Fax: formatMobileNumber(pcp.fax_number),
    };

    useEffect(() => {
        const asyncFn = async () => {
            const options = { params: { showLoader: false } };
            const provider_meta_data = await api.patient.get_provider_meta_data(options);
            setPcp(provider_meta_data);

            const insuranceCompanies = await props.fetchInsuranceList(options);
            setInsuranceCompaniesList(insuranceCompanies.carriers);
            setPolicyMetadata(await publicRequests.get_policy_metadata());
        };
        asyncFn();
    }, []);

    const consentHandler = async (policyName, policyVersion) => {
        const currConsent = _.cloneDeep(consent);
        const newConsent = createAcknowledgementV2({
            policyVersion,
            onBehalfOfAcknowledgement: true,
        });
        currConsent[policyName] = consent[policyName] == null ? [newConsent] : null;
        setConsent(currConsent);
    };

    const handleOnClickAutoFill = async (name, formikProps) => {
        const clonedFormCheckboxStates = _.cloneDeep(formCheckboxStates);
        const newFormVal = {};
        clonedFormCheckboxStates[name] = !clonedFormCheckboxStates[name];
        if (name === "child_address_autofill") {
            const formName = "address";
            newFormVal[formName] = clonedFormCheckboxStates[name]
                ? props.profile.address
                : INITIAL_VALUES_V2["address"];
        } else if (name === "default_child_practice_autofill") {
            const formName = "pcp_id";
            newFormVal[formName] = clonedFormCheckboxStates[name] ? "" : props.profile.pcp_id;
        } else if (name === "child_sms_consent") {
            const formName = "consent";
            newFormVal[formName] = clonedFormCheckboxStates[name]
                ? {
                      sms_is_enabled: [
                          createAcknowledgementV2({
                              onBehalfOfAcknowledgement: true,
                          }),
                      ],
                  }
                : {};
        }
        formikProps.setValues({
            ...formikProps.values,
            ...newFormVal,
            ...clonedFormCheckboxStates,
        });
        setFormCheckboxStates(clonedFormCheckboxStates);
    };

    const renderParentPatientPracticeInfo = (parentPatient, className) => (
        <div className={className}>
            {Object.keys(PARENT_PATIENT_INFO).map((key) => {
                return (
                    <div className={"row"}>
                        <div className={"col fw-bold"}>
                            {(key === "Phone" || key === "Fax") && key + ":"}{" "}
                            {PARENT_PATIENT_INFO[key]}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const renderFormRow = (schema, formikProps, containerClass) => {
        return schema.map((formEl) => {
            if (
                formEl.name === "child_address_autofill" &&
                props.profile.address.state !== "New York"
            ) {
                return null;
            }

            if (!containerClass) {
                containerClass =
                    formEl.elementType === "textarea"
                        ? "col-12"
                        : formEl.name === "dob" || formEl.name === "gender"
                        ? "col-6"
                        : "col-12 col-xl-6";
            }
            if (
                formEl.name === "child_address_autofill" ||
                formEl.name === "default_child_practice_autofill" ||
                formEl.name === "child_sms_consent"
            ) {
                formEl.onclick = (event) => handleOnClickAutoFill(event, formikProps);
            }

            const componentProps = {
                formEl,
                formikProps,
                value: formikProps.values[formEl.name],
                errors: formikProps.errors,
                touched: formikProps.touched,
                onChange: formEl.onchange
                    ? formEl.onchange
                    : async (val) => {
                          await formikProps.setFieldValue(formEl.name, val.value);
                          formikProps.setFieldTouched(formEl.name);
                      },
                onclick: formEl.onclick,
            };

            let input = <FormikInput {...componentProps} />;
            if (
                formEl.name === "mobile" ||
                formEl.name === "emergency_contact_no" ||
                formEl.name === "dob"
            ) {
                input = <FormikInputFormat {...componentProps} />;
            }
            return (
                <div key={formEl.name} className={containerClass}>
                    {input}
                </div>
            );
        });
    };

    const renderForm = (formikProps) => {
        return (
            <>
                {insuranceDetails && (
                    <InsuranceEligibilityWrapper
                        submissionAttempts={insuranceEligibilitySubmissionAttempts}
                        insuranceDetails={insuranceDetails}
                        eligibilityCallback={insuranceEligibilityCallback}
                        entryPoint={"teens_kids_program"}
                    />
                )}

                <Form>
                    <div className="mx-auto px-0">
                        <div className="row pb-2">
                            {renderFormRow(BASIC_CONTACT_INFO_SCHEMA, formikProps)}
                        </div>
                        <div className={"row pb-2 pl-4"}>
                            {renderFormRow(CHILD_SMS_CONSENT, formikProps, "col-12")}
                        </div>
                        <div className={"row pb-2 pl-4"}>
                            <h6 key={"address"} className="col-12 mt-xl-3 pl-0">
                                <b>{"Address"}</b>
                            </h6>
                            {renderFormRow(
                                CHILD_ADDRESS_AUTOFILL,
                                formikProps,
                                "col-12 col-xl-6 pl-0",
                            )}
                        </div>

                        <div className="row pb-3 pl-4">
                            {renderFormRow(BASIC_ADDRESS_SCHEMA, formikProps)}
                        </div>
                        <div className="row border-top pt-3 pl-4">
                            {renderFormRow(BASIC_EMERGENCY_CONTACT_SCHEMA, formikProps)}
                        </div>
                        <div className="row border-top pt-3 pl-4">
                            <h6 key={"practice"} className="col-12 mt-xl-3 pl-0">
                                <b>Child's Primary Care Physician</b>
                            </h6>
                            <span className={"fs-14 mb-2"}>
                                Please confirm the information below is the correct Primary Care
                                Practice for your child:
                            </span>
                            {renderParentPatientPracticeInfo({}, "col-12")}
                            {renderFormRow(DEFAULT_CHILD_PRACTICE_AUTOFILL, formikProps, "col-12")}
                        </div>
                        <div className="row border-top pt-3 pl-4">
                            <h6 key={"insurance"} className="col-12 mt-xl-3 pl-0">
                                <b>{"Primary Insurance Info"}</b>
                            </h6>
                            <InsuranceForm
                                teensAndKids={true}
                                formikProps={formikProps}
                                initialValues={initialValues}
                                insuranceCompaniesList={insuranceCompaniesList}
                                submitAcknowledgementHandler={() =>
                                    submitAcknowledgement(formikProps.values, formikProps.actions)
                                }
                                closeAcknowledgementHandler={() =>
                                    setShowAcknowledgementPopup(false)
                                }
                                showAcknowledgementPopup={showAcknowledgementPopup}
                            />
                        </div>
                    </div>
                    <div className={"mx-4 my-5 fs-15 txt-gry"}>
                        <ConsentToTreatment
                            version={policyMetadata[CONSENT_TO_TREATMENT]?.current_version}
                            consentEvent={() =>
                                consentHandler(
                                    "treatment",
                                    policyMetadata[CONSENT_TO_TREATMENT]?.current_version,
                                )
                            }
                        />
                    </div>
                    <div className={"row justify-content-center"}>
                        <Button
                            className={"Btn Btn-sm Btn--pri "}
                            type={"submit"}
                            disabled={
                                disableSubmit ||
                                insuranceEligibilitySubmissionAttempts >= 5 ||
                                !consent?.treatment
                                    ? "disabled"
                                    : null
                            }>
                            Agree & Continue
                        </Button>
                    </div>
                    <ConsentDisclaimer policyMetadata={policyMetadata} />
                </Form>
            </>
        );
    };

    const applyInsPrefix = (s3Location) => {
        if (
            s3Location &&
            s3Location !== "None" &&
            !s3Location.includes("backend/insurance_cards/")
        ) {
            return "backend/insurance_cards/" + s3Location;
        } else {
            return s3Location;
        }
    };

    const isAcknowledgementRequired = (carrier) => {
        return CATCH_ALL_INSURANCE_OPTIONS.includes(carrier);
    };

    useEffect(() => {
        if (accountData && !_.isEmpty(accountData.acknowledgements)) {
            setShowAcknowledgementPopup(false);
            addPatientAndChangeWorkflowStep();
        }
    }, [accountData]);

    const submitAcknowledgement = async (formData, actions) => {
        const acknowledgement = await createInsurancePolicyAcknowledgement(
            formData.insurance.carrier,
        );
        formData.acknowledgements = {
            ...formData.acknowledgements,
            ...acknowledgement,
        };
        await transformAccountData(formData);
    };

    const submitOrOpenAcknowledgement = async (formData, actions) => {
        if (isAcknowledgementRequired(formData.insurance.carrier)) {
            setShowAcknowledgementPopup(true);
            return;
        }

        setShowAcknowledgementPopup(false);
        await transformAccountData(formData, actions);
        setInsuranceEligibilitySubmissionAttempts(insuranceEligibilitySubmissionAttempts + 1);
    };

    const addPatientAndChangeWorkflowStep = () => {
        props.addNewPatient(accountData, async (response) => {
            if (!response || !_.get(response, "patient_id")) {
                return;
            }
            try {
                const { first_name, last_name, address, dob } = accountData;
                props.setProviderMetaData(pcp);
                props.setPatientMetaData({
                    patient_id: response["patient_id"],
                    first_name,
                    last_name,
                    address,
                    pcp_id: response["pcp_id"],
                    dob,
                    email: accountData.email,
                });
                trackEvent("Release of Information");
                trackInsurancePolicyAcknowledgement(accountData.primary_insurance.carrier);
                props.changeWorkFlowStep(releaseForm, `patientId=${response["patient_id"]}`);
            } catch (e) {
                console.error(e);
            }
        });
    };

    const handleInsuranceEligibilityCallback = (errorMsg, submissionAttempts, response) => {
        if (response.status === "declined") {
            setDisableSubmit(true);
            return;
        }

        if (
            response.eligibility === "no_match" ||
            response.eligibility === "declined" ||
            response.status == "no_match"
        ) {
            setDisableSubmit(errorMsg !== "" && insuranceEligibilitySubmissionAttempts >= 5);
            return;
        }

        if (insuranceEligibilitySubmissionAttempts < 5) {
            addPatientAndChangeWorkflowStep();
        }
    };

    const insuranceEligibilityCallback = (errorMsg, submissionAttempts, response) => {
        featureFlagFunction({
            flagName: OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG,
            enabled: publicFlags?.[OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG],
            hasLoaded: publicFlagsHaveLoaded,
            defaultEnabled: true,
            enabledBehavior: () => {
                handleInsuranceEligibilityCallback(errorMsg, submissionAttempts, response);
            },
            alternateBehavior: () => {
                addPatientAndChangeWorkflowStep();
            },
        });
    };

    const transformAccountData = async (formData, actions) => {
        const formDataCloned = _.cloneDeep(formData);
        const formattedDOB = `${formDataCloned["dob"].substring(0, 2)}/${formDataCloned[
            "dob"
        ].substring(2, 4)}/${formDataCloned["dob"].substring(4, 8)}`;
        formDataCloned["dob"] = formattedDOB;
        if (!CATCH_ALL_INSURANCE_OPTIONS.includes(formDataCloned.insurance.carrier)) {
            formDataCloned.insurance.policy_dob = formattedDOB;
            formDataCloned.insurance.state = formDataCloned.address.state;
        } else {
            initialValues.insurance.policy_holder_name = "";
            initialValues.insurance.policy_relationship = "";
        }
        formDataCloned["primary_insurance"] = formDataCloned["insurance"];
        formDataCloned["primary_insurance"]["dob"] = formattedDOB;
        formDataCloned["primary_insurance"]["card_front"] = applyInsPrefix(
            formDataCloned["primary_insurance"]["card_front"],
        );
        formDataCloned["primary_insurance"]["card_back"] = applyInsPrefix(
            formDataCloned["primary_insurance"]["card_back"],
        );
        formDataCloned["secondary_insurance"] = {
            state: "",
            carrier: "",
            card_front: "",
            card_back: "",
            member_id: "",
        };
        delete formDataCloned.insurance;
        formDataCloned["group_type"] = "teens_kids_program";

        formDataCloned["side_load_config"] = {
            patient: {
                assessment_config: {
                    skip_all: true,
                    skip_a30: true,
                    skip_a5: true,
                },
            },
        };

        const implicitConsents = createImplicitConsentV2(policyMetadata, true);
        formDataCloned["consent"] = {
            ...formDataCloned["consent"],
            ...consent,
            ...implicitConsents,
        };

        const insuranceInfo = {
            ...formDataCloned["primary_insurance"],
            policy_dob: moment(formDataCloned.dob, "MMDDYYYY").format("MM/DD/YYYY"),
            first_name: formDataCloned.first_name,
            last_name: formDataCloned.last_name,
            email: formDataCloned.email,
            dob: moment(formDataCloned.dob, "MMDDYYYY").format("MM/DD/YYYY"),
            area: "teens_kids_program",
            carrier: formDataCloned.primary_insurance.carrier,
            member_id: formDataCloned.primary_insurance.member_id,
            group_id: formDataCloned?.primary_insurance?.group_id,
        };

        setInsuranceDetails(insuranceInfo);
        setAccountData(formDataCloned);
    };

    const renderContainer = (initialValues) => {
        return (
            <div className={"container pt-3 pb-5"}>
                <div className={"txt-sec fw-bold pt-2 mx-3 workflow-step-title"}>
                    Step 1 of 3: Add Your Child’s Information
                </div>
                <div className={"col mt-3"}>
                    <CustomForm
                        initialValues={initialValues}
                        validationSchema={CHILD_VALIDATION_SCHEMA()}
                        onSubmit={submitOrOpenAcknowledgement}
                        render={renderForm}
                    />
                </div>
            </div>
        );
    };
    return renderContainer(initialValues);
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        configs: state.configs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ addNewPatient, fetchInsuranceList }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeenChildSignup));

import React from "react";

import {
    RadioGroup,
    RadioOld,
    Text,
    TextTypes,
    TextColors,
} from "../../../../../component-library";
import { getIn } from "formik";
import _ from "lodash";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const CareManagementRadioField = ({
    title,
    subTitle,
    radioOptions = [],
    name,
    formikProps,
    isViewOnly,
    testId,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <div className="CareManagementFieldShared--wrapper">
            <div className="CareManagementFieldShared--text_wrapper">
                <h3 className="CareManagementFieldShared--title">{title}</h3>
                <h3 className="CareManagementFieldShared--subTitle">{subTitle}</h3>
            </div>
            <div className={"CareManagementRadioField--radio_wrapper"}>
                <RadioGroup
                    disabled={isViewOnly}
                    className={"CareManagementRadioField--radio_group"}
                    name={title}
                    onChange={handleChange}
                    value={_.get(formikProps.values, name)}>
                    {radioOptions.map((option) => {
                        return (
                            <RadioOld
                                labelClassName={"CareManagementRadioField--radio_label"}
                                radioClassName={"CareManagementFieldShared--radio_button"}
                                label={option.label}
                                value={option.value}
                                {...getAutomationDataAttr(testId)}
                            />
                        );
                    })}
                </RadioGroup>
                {errorMessage && isTouched && (
                    <Text type={TextTypes.small} color={TextColors.red} layout="block">
                        {errorMessage}
                    </Text>
                )}
            </div>
        </div>
    );
};

import React from "react";
import Input from "../../components/UI/Input";
import "./styles.scss";
import { createClassNameString } from "../utils";

export const ConsentSMS = ({ consentEvent, children, className, type, color, ...props }) => (
    <label className={createClassNameString(["ConsentSMS--container", className])}>
        <Input className="ConsentSMS--checkbox" type={"checkbox"} onClick={() => consentEvent()} />
        <span className="ConsentSMS--text">
            I consent to receive marketing, appointment reminders, and other calls and text messages
            (SMS/MMS) from aptihealth, healthcare providers with whom I am matched by aptihealth, or
            any of their agents or representatives at the phone number I have provided, even if that
            number is registered on a corporate, state, or national do not call registry. I
            understand that my consent to such calls and messages is not required to use
            aptihealth’s services and that I may revoke my consent at any time. Message and data
            rates may apply. Message frequency varies.
        </span>
    </label>
);

ConsentSMS.defaultProps = {
    consentEvent: () => {},
};

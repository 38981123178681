// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import {
    EMERGENCY_CONTACT_SCHEMA,
    INITIAL_VALUES,
    PATIENT_ADDRESS_SCHEMA,
    PRIMARY_PATIENT_INFORMATION_SCHEMA,
} from "./schema/patientSignUpForm";
import {
    GUARDIANSHIP_VALIDATION_SCHEMA,
    VALIDATION_SCHEMA,
} from "./schema/patientSignUpValidation";
import { Field, Form } from "formik";
import FormikInput from "../../components/UI/FormikInput";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import { completeAutoSignUp, signUp } from "../../redux/actions/auth";
import { PcpDetails } from "../../components/Authentication";
import images from "../../utils/images";
import _, { cloneDeep as _cloneDeep } from "lodash";
import { connect } from "react-redux";
import { showAlertWithAction } from "../../redux/actions/alerts";
import { api } from "../../APIRequests";
import FormikInputFormat from "../../components/UI/formikMobileInput";
import { CustomForm, Footer, MemberReferralInsuranceHOC } from "../../component-library";
import moment from "moment";
import InterestInGuardianship, {
    GUARDIANSHIP_PROGRAM_TYPE,
} from "../../components/Common/InterestInGuardianship";
import { trackEvent } from "../../utils/EventTrackingUtil";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../constants/insurnaceIconMap";
import {
    createAcknowledgement,
    createInsurancePolicyAcknowledgement,
    trackInsurancePolicyAcknowledgement,
} from "../../utils/userAgreementUtil";
import InsuranceEligibilityWrapper from "../../components/Common/InsuranceEligibility/InsuranceEligibilityWrapper";
import { tagReferralPath } from "../../utils/logRocketUtils";
import { featureFlagFunction } from "../../utils/featureFlag";
import { OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG } from "../../constants/featureFlags";
import {
    ConsentDisclaimer,
    createImplicitConsent,
} from "../../components/Common/ConsentDisclaimer";
import publicRequests from "../../APIRequests/public";
import { ConsentToTreatment } from "../../component-library/ConsentToTreatment";
import { CONSENT_TO_TREATMENT } from "../../components/Policy";

class PatientSignUp extends Component {
    constructor(props) {
        super(props);
        this.setFieldValue = null; //formiks utility function
        this.state = {
            insEditPri: false,
            is_sms_enabled: false,
            initialValues: null,
            insuranceEligibilityResponse: null,
            insuranceEligibilityErrorMessage: "",
            insuranceEligibilitySubmissionAttempts: 0,
            disableSubmit: false,
            interestedInGuardianship: false,
            consent: {
                treatment: null,
            },
            carriersOptions: [],
            acknowledgements: {},
            showAcknowledgementPopup: false,
            insuranceDetails: {},
            accountData: {},
            validationSchema: VALIDATION_SCHEMA,
            policyMetadata: {},
        };
    }

    async componentDidMount() {
        /*
         * get dropdown list options for insurance fields from api
         */
        const options = { params: { showLoader: false } };
        api.patient
            .fetch_insurance_list({ options })
            .then((cleanResponse) => {
                let carriersOptions = cleanResponse.carriers.map((carriers) => {
                    return { value: carriers, display: carriers };
                });
                this.setState({
                    carriersOptions: carriersOptions,
                });
            })
            .catch((err) => {
                console.log(err);
            });
        this.setState({
            policyMetadata: await publicRequests.get_policy_metadata(),
        });
        this.handleReferralResponse();
    }

    /*
     * modify and set the INITIAL_VALUES after receiving
     * data from referral code submission
     */
    handleReferralResponse = () => {
        tagReferralPath(this.props.referralResponse.group_type);
        let Username = this.props.referralResponse.email;
        let updatedInitialValues = {
            ...INITIAL_VALUES,
            ...this.props.referralResponse,
        };
        delete updatedInitialValues["email"];
        updatedInitialValues["Username"] = Username;

        const dob = updatedInitialValues.hasOwnProperty("dob")
            ? updatedInitialValues["dob"].replace(/\//g, "")
            : "";
        updatedInitialValues["dob"] = dob;
        this.transformInsuranceData(updatedInitialValues, dob);
        this.setState({
            initialValues: updatedInitialValues,
        });
    };

    transformInsuranceData = (updatedInitialValues, dob) => {
        if (updatedInitialValues.primary_insurance) {
            let pri_cloned = { ...updatedInitialValues.primary_insurance };
            let pri_shouldAdd =
                pri_cloned.state ||
                pri_cloned.carrier ||
                pri_cloned.member_id ||
                pri_cloned.card_front ||
                pri_cloned.card_back;
            if (pri_shouldAdd) {
                updatedInitialValues.insurance = pri_cloned;
            }
        }

        if (!updatedInitialValues.insurance.policy_relationship) {
            updatedInitialValues.insurance.policy_relationship = "self";
        }

        if (!updatedInitialValues.insurance.policy_dob) {
            updatedInitialValues.insurance.policy_dob = dob;
            updatedInitialValues.insurance.policy_holder_dob = dob;
            updatedInitialValues.insurance.dob = dob;
        }
    };
    alteredFormData = (data) => {
        let alteredData = _.cloneDeep(data);

        let DOBnumber = {
            dob: `${alteredData.dob.substring(0, 2)}/${alteredData.dob.substring(
                2,
                4,
            )}/${alteredData.dob.substring(4, 8)}`,
            primaryDOB: alteredData.insurance.policy_dob,
        };

        if (!alteredData.insurance.policy_dob.includes("/")) {
            DOBnumber["primaryDOB"] = `${DOBnumber.primaryDOB.substring(
                0,
                2,
            )}/${DOBnumber.primaryDOB.substring(2, 4)}/${DOBnumber.primaryDOB.substring(4, 8)}`;
        }

        return DOBnumber;
    };

    isAcknowledgementRequired = (carrier) => {
        return CATCH_ALL_INSURANCE_OPTIONS.includes(carrier);
    };

    submitAndCloseAcknowledgement = async (formData, actions) => {
        const acknowledgement = await createInsurancePolicyAcknowledgement(
            formData.insurance.carrier,
        );
        const acknowledgements = {
            ...this.state.acknowledgements,
            ...acknowledgement,
        };
        this.setState(
            {
                showAcknowledgementPopup: false,
                acknowledgements,
            },
            () => this.submitHandler(formData, actions),
        );
        trackInsurancePolicyAcknowledgement(formData.insurance.carrier);
    };

    submitOrOpenAcknowledgement = async (formData, actions) => {
        if (this.isAcknowledgementRequired(formData.insurance.carrier)) {
            this.setState({ showAcknowledgementPopup: true });
        } else {
            await this.submitHandler(formData, actions);
        }
    };

    submitHandler = async (formData, actions) => {
        // This is registration
        if (this.props.campaignId === "hcp_welcome_v1") {
            trackEvent("HCP Member Registers", { practiceId: this.props.practiceId });
        }

        const newDOBData = this.alteredFormData(formData);
        const newFormData = _.cloneDeep(formData);

        newFormData.dob = newDOBData.dob;
        newFormData.insurance.policy_dob = newDOBData.primaryDOB;

        if (CATCH_ALL_INSURANCE_OPTIONS.includes(newFormData["insurance"]["carrier"])) {
            newFormData["insurance"] = {
                carrier: newFormData["insurance"]["carrier"],
                card_back: "",
                card_front: "",
                group_id: "",
                member_id: "",
                policy_dob: "",
                policy_holder_name: "",
                policy_relationship: "",
                state: "",
            };
            newFormData.acknowledgements = _cloneDeep(this.state.acknowledgements);
        }

        // skip if the optional fields for guardians if they are left blank
        const skipInsuranceEligibilityCheck =
            this.state.interestedInGuardianship &&
            (!newFormData.insurance.carrier || !newFormData.insurance.member_id);

        const implicitConsents = await createImplicitConsent(this.state.policyMetadata);
        newFormData.consent = { ...implicitConsents, ...this.state.consent };

        if (skipInsuranceEligibilityCheck) {
            this.setState(
                {
                    accountData: newFormData,
                },
                () => this.completeSignup(),
            );
        } else {
            const insuranceInfo = {
                ...newFormData["insurance"],
                policy_dob: moment(newFormData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
                first_name: newFormData.first_name,
                last_name: newFormData.last_name,
                email: newFormData.Username,
                dob: moment(newFormData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
                area: "patient",
                carrier: newFormData.insurance.carrier,
                member_id: newFormData.insurance.member_id,
                group_id: newFormData.insurance.group_id,
            };

            this.setState({
                insuranceDetails: insuranceInfo,
                accountData: newFormData,
                insuranceEligibilitySubmissionAttempts:
                    this.state.insuranceEligibilitySubmissionAttempts + 1,
            });
        }
    };

    transformAccountData = () => {
        const accountDataCloned = _.cloneDeep(this.state.accountData);
        // pull dob values set by the submitHandler
        accountDataCloned.insurance.dob = this.state.insuranceDetails.dob || "";
        accountDataCloned.insurance.policy_dob = this.state.insuranceDetails.policy_dob || "";
        // if the "Are you the Policy Holder?" question has not been clicked
        // MemberReferralInsuranceHOC will not manipulate the policy_holder_name
        // meaning the name will be set to '', by default the user filling out the form
        // is the policy holder so we use their name in this case
        // otherwise MemberReferralInsuranceHOC will control the policy_holder_name field
        if (
            accountDataCloned.insurance.policy_holder_name === "" &&
            this.state.insuranceDetails.first_name &&
            this.state.insuranceDetails.last_name
        ) {
            accountDataCloned.insurance.policy_holder_name = `${this.state.insuranceDetails.first_name} ${this.state.insuranceDetails.last_name}`;
        }

        accountDataCloned["preferences"] = { is_sms_enabled: this.state.is_sms_enabled };

        accountDataCloned["primary_insurance"] = accountDataCloned["insurance"];
        accountDataCloned["secondary_insurance"] = {
            state: "",
            carrier: "",
            card_front: "",
            card_back: "",
            member_id: "",
        };
        accountDataCloned["group_type"] = "patient";

        accountDataCloned.interested_in_guardianship = this.state.interestedInGuardianship;

        if (this.state.interestedInGuardianship && !accountDataCloned.guardianship_program_type) {
            accountDataCloned.guardianship_program_type = GUARDIANSHIP_PROGRAM_TYPE.DEFAULT;
        }

        //cleaning up preparing form for submit
        delete accountDataCloned.insurance;
        delete accountDataCloned.ConfirmPassword;
        delete accountDataCloned.pcp_address;
        delete accountDataCloned.uuid;

        return accountDataCloned;
    };

    handleInsuranceEligibilityCallback = (errorMsg, submissionAttempts, response) => {
        if (
            response.eligibility === "no_match" ||
            response.eligibility === "declined" ||
            response.status === "no_match"
        ) {
            this.setState({
                disableSubmit: errorMsg !== "" && submissionAttempts >= 5,
            });
            return;
        } else if (response.status === "declined") {
            this.setState({
                disableSubmit: true,
            });
            return;
        }
        this.completeSignup();
    };

    insuranceEligibilityCallback = (errorMsg, submissionAttempts, response) => {
        featureFlagFunction({
            flagName: OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG,
            enabled: this.props?.flags?.publicFlags?.[OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG],
            hasLoaded: this.props?.flags?.publicFlagsHaveLoaded,
            defaultEnabled: true,
            enabledBehavior: () => {
                this.handleInsuranceEligibilityCallback(errorMsg, submissionAttempts, response);
            },
            alternateBehavior: () => {
                this.completeSignup();
            },
        });
    };

    completeSignup = () => {
        const accountDataCloned = this.transformAccountData();
        if (!this.props.referralResponse.patient_id) {
            this.props.signUp(accountDataCloned);
        } else {
            accountDataCloned["code"] = accountDataCloned["referralCode"];
            accountDataCloned["email"] = accountDataCloned["Username"];
            accountDataCloned["password"] = accountDataCloned["Password"];
            this.props.completeAutoSignUp(accountDataCloned);
        }
    };

    preferences_handler = () => {
        this.setState((prevState) => ({
            is_sms_enabled: !prevState.is_sms_enabled,
        }));
    };

    renderFormattedFormikInput = (formEl, formikProps) => {
        return (
            <FormikInputFormat
                value={formikProps && formikProps.values[formEl.name]}
                onChange={async (val) => {
                    await formikProps.setFieldValue(formEl.name, val.value);
                    formikProps.setFieldTouched(formEl.name);
                }}
                formEl={formEl}
                errors={formikProps.errors}
                touched={formikProps.touched}
            />
        );
    };

    renderDefaultFormikInputContent = (formEl, formikProps) => {
        if (formEl.formatted) {
            return this.renderFormattedFormikInput(formEl, formikProps);
        } else {
            return (
                <FormikInput
                    formEl={formEl}
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                />
            );
        }
    };

    renderDefaultFormikInput = (formEl, formikProps) => {
        return (
            <div
                key={formEl.name}
                className={
                    formEl.elementType === "textarea"
                        ? "col-12"
                        : formEl.name === "dob" || formEl.name === "gender"
                        ? "col-6"
                        : "col-12 col-xl-6"
                }>
                {this.renderDefaultFormikInputContent(formEl, formikProps)}
            </div>
        );
    };

    renderSegment = (formEl) => {
        return (
            <h6 key={formEl.displayName} className="col-12 mt-xl-3">
                <b>{formEl.displayName}</b>
                {formEl.displayName === "Primary Insurance Information" &&
                    this.state.interestedInGuardianship && (
                        <span className="ml-1 txt-gry">(Optional)</span>
                    )}
            </h6>
        );
    };

    renderFromSchema = (schema, formikProps, arrayHelper) => {
        return schema.map((formEl, i) => {
            switch (formEl.elementType) {
                case "segment":
                    return this.renderSegment(formEl);
                default:
                    return this.renderDefaultFormikInput(formEl, formikProps);
            }
        });
    };

    consentHandler = async (policyName, policyVersion) => {
        let currConsent = _.cloneDeep(this.state.consent);
        const newConsent = {
            ...(await createAcknowledgement()),
            version: policyVersion,
        };

        if (this.state.consent[policyName] == null) {
            currConsent[policyName] = [newConsent];
            this.setState({ consent: currConsent });
        } else {
            currConsent[policyName] = null;
            this.setState({ consent: currConsent });
        }
    };

    renderForm = (formikProps) => {
        this.setFieldValue = formikProps.setFieldValue;
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    {this.state.insuranceDetails && (
                        <InsuranceEligibilityWrapper
                            submissionAttempts={this.state.insuranceEligibilitySubmissionAttempts}
                            insuranceDetails={this.state.insuranceDetails}
                            eligibilityCallback={this.insuranceEligibilityCallback}
                            entryPoint={"referral_signup"}
                        />
                    )}
                    <div className="row">
                        {this.renderFromSchema(PRIMARY_PATIENT_INFORMATION_SCHEMA, formikProps)}

                        {
                            <label className="col-12 d-flex mt-4">
                                <div className="AddPatientInputContainer">
                                    <Input
                                        type="checkbox"
                                        name="preferences"
                                        className="ConsentToTreatment--checkbox"
                                        onClick={this.preferences_handler}
                                    />
                                </div>
                                <div className="checkbox-text fs-15 ml-2">
                                    Check this box if you would like to receive calls, text messages
                                    (including SMS and MMS messages), and other electronic messages
                                    (including those made using an automatic telephone dialing
                                    system or an artificial or prerecorded voice) from aptihealth
                                    healthcare providers with whom I am matched by aptihealth, or
                                    any of their agents or representatives at the phone number I
                                    have provided even if that number is registered on a corporate,
                                    state, or national do not call registry. I understand that my
                                    consent to such calls and messages is not required to use
                                    aptihealth’s services and that I may contact aptihealth at{" "}
                                    <a
                                        className={"apti-green-v2 fs-15 text-decoration-underline"}
                                        href={"mailto:support@aptihealth.com"}>
                                        info@aptihealth.com
                                    </a>{" "}
                                    or{" "}
                                    <a
                                        className={"apti-green-v2 fs-15 text-decoration-underline"}
                                        href={"tel:8884543827"}>
                                        888-454-3827
                                    </a>{" "}
                                    about its services or to revoke this consent at any time.
                                </div>
                            </label>
                        }

                        <div className="col-12 mt-3 mb-4">
                            <hr />
                        </div>

                        {this.renderFromSchema(
                            PATIENT_ADDRESS_SCHEMA(this.state.interestedInGuardianship),
                            formikProps,
                        )}
                        {this.renderFromSchema(EMERGENCY_CONTACT_SCHEMA, formikProps)}

                        <div className={"my-3 d-flex fs-15"}>
                            <InterestInGuardianship
                                interestedInGuardianship={this.state.interestedInGuardianship}
                                setInterestedInGuardianship={() =>
                                    this.setState((prevState) => {
                                        const newInterestedInGuardianship =
                                            !prevState.interestedInGuardianship;
                                        return {
                                            interestedInGuardianship: newInterestedInGuardianship,
                                            validationSchema: newInterestedInGuardianship
                                                ? GUARDIANSHIP_VALIDATION_SCHEMA
                                                : VALIDATION_SCHEMA,
                                        };
                                    })
                                }
                            />
                        </div>

                        <div className="col-12 mt-3 mb-4">
                            <hr />
                        </div>
                        {
                            <Field
                                name="insurance" // name = "insurance must be same as define in initialValues schema of formik"
                                render={() => {
                                    return (
                                        <Fragment>
                                            {/*  Primary insurance section */}
                                            {
                                                <MemberReferralInsuranceHOC
                                                    className="AddInsurance--patient-signup"
                                                    isPrimaryInsurance={true}
                                                    formikProps={formikProps}
                                                    insuranceCompanyOptions={
                                                        this.state.carriersOptions
                                                    }
                                                    firstName={formikProps.values.first_name}
                                                    lastName={formikProps.values.last_name}
                                                    submitAcknowledgementHandler={() =>
                                                        this.submitAndCloseAcknowledgement(
                                                            formikProps.values,
                                                        )
                                                    }
                                                    closeAcknowledgementHandler={() =>
                                                        this.setState({
                                                            showAcknowledgementPopup: false,
                                                        })
                                                    }
                                                    showAcknowledgementPopup={
                                                        this.state.showAcknowledgementPopup
                                                    }
                                                />
                                            }
                                        </Fragment>
                                    );
                                }}
                            />
                        }
                        <div className={"my-4 fs-15 txt-gry"} style={{ padding: "0 15px" }}>
                            <ConsentToTreatment
                                version={
                                    this.state.policyMetadata[CONSENT_TO_TREATMENT]?.current_version
                                }
                                consentEvent={() =>
                                    this.consentHandler(
                                        "treatment",
                                        this.state.policyMetadata[CONSENT_TO_TREATMENT]
                                            ?.current_version,
                                    )
                                }
                            />
                        </div>
                    </div>
                    {/* PCP details */}
                    {this.props.showPcpDetails && (
                        <PcpDetails
                            address={this.props.referralResponse.pcp_address}
                            pcp_name={this.props.referralResponse.pcp_name}
                            referralCode={this.props.referralResponse.referralCode}
                        />
                    )}
                </div>

                <div className="text-center mb-5">
                    <Button
                        type="submit"
                        className="Btn Btn--pri Btn-sm mb-2"
                        disabled={
                            this.state.disableSubmit ||
                            !this.state.consent.treatment ||
                            this.state.insuranceEligibilitySubmissionAttempts >= 5
                                ? "disabled"
                                : null
                        }
                        data-e2e={"patient-sign-up-submit-button"}>
                        Continue
                    </Button>
                    <ConsentDisclaimer policyMetadata={this.state.policyMetadata} />
                </div>
            </Form>
        );
    };

    render() {
        return (
            <>
                {this.state.initialValues && (
                    <div>
                        <div style={{ "margin-bottom": "30px" }}>
                            <img className="img-fluid" src={images("./consent-header.png")} />
                        </div>
                        <div className={"my-4"}>
                            <div className={"fs-24 fw-bold text-center"}>
                                Welcome to aptihealth!
                            </div>
                            <div className={"fs-18 text-center txt-gry"}>
                                Let’s get started by creating your account
                            </div>
                        </div>
                        <div className="SignUp--step3 padding">
                            <CustomForm
                                initialValues={this.state.initialValues}
                                validationSchema={this.state.validationSchema}
                                onSubmit={this.submitOrOpenAcknowledgement}
                                render={this.renderForm}
                            />
                        </div>
                        <Footer />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        referralResponse: state.auth.referralResponse,
        configs: state.configs,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, {
    signUp,
    showAlertWithAction,
    completeAutoSignUp,
})(PatientSignUp);

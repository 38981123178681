import React, { useEffect, useState } from "react";
import { ListBody, ListHeader } from "../../../UI/ListView";
import { Button, ButtonTypes, Text, TextTypes } from "../../../../component-library/";
import ConsentModal from "./ConsentModal";
import { api } from "../../../../APIRequests";
import PropTypes from "prop-types";
import { ConsentListItems } from "./ConsentListItems";
import Hr from "../../../UI/Hr";
import { connect, useSelector } from "react-redux";
import FeatureGate from "../../../Common/FeatureGate/FeatureGate";
import { TEMPORARY_DISABLE_PATIENT_CHART_CONSENT } from "../../../../constants/featureFlags";

const MemberProfileConsentList = (props) => {
    const { consents } = props;
    const [consentData, setConsentData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isViewOnly, setIsViewOnly] = useState(false);
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);

    const getPatientConsent = async () => {
        const legacyConsents = consents.filter((consent) => consent?.consent_form_version == null);
        setConsentData(legacyConsents);
    };

    useEffect(() => {
        const userType = props?.profile?.user_type
            ? props?.profile?.user_type
            : props?.profile?.provider_type;
        setIsViewOnly(userType !== "CSE" && userType !== "BEHAVIORAL_INTAKE");
        getPatientConsent();
    }, []);

    const renderConsentList = () => {
        return (
            <ListBody classes="List__body_lg consent-list my-3">
                <ListHeader title={"Consent"} />
                {!consentData || (consentData && consentData.length === 0) ? (
                    <>
                        <div className="px-3 px-lg-4">
                            <Hr />
                        </div>
                        <div className="consent-empty-list">
                            <Text type={TextTypes.paragraph} className="font-color-gray">
                                No consents currently on file.
                            </Text>
                        </div>
                    </>
                ) : (
                    <ConsentListItems consentData={consentData} />
                )}
                {!isViewOnly && (
                    <Button
                        buttonType={ButtonTypes.primaryOutlineV2}
                        onClick={() => setShowModal((show) => !show)}
                        className="m-3 m-lg-4">
                        Add
                    </Button>
                )}
                <ConsentModal
                    consentFormName={"CONSENT_TO_TREATMENT"}
                    show={showModal}
                    setShowModal={setShowModal}
                    patientDetails={props?.patientDetails}
                    refreshConsentList={getPatientConsent}
                />
            </ListBody>
        );
    };

    const renderLegacyConsentList = () => {
        return (
            consentData?.length > 0 && (
                <ListBody classes="List__body_lg consent-list my-3">
                    <ListHeader title={"Legacy Consents"} />
                    <ConsentListItems consentData={consentData} />
                </ListBody>
            )
        );
    };

    return (
        <FeatureGate
            flagName={TEMPORARY_DISABLE_PATIENT_CHART_CONSENT}
            defaultFlagBehavior={true}
            enabled={privateFlags?.[TEMPORARY_DISABLE_PATIENT_CHART_CONSENT]}
            alternateFlagDisabledBehavior={renderConsentList()}
            hasLoaded={privateFlagsHaveLoaded}>
            {renderLegacyConsentList()}
        </FeatureGate>
    );
};

MemberProfileConsentList.propTypes = {
    patientDetails: PropTypes.object,
    profile: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(MemberProfileConsentList);

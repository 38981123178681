// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import publicRequests from "../../APIRequests/public";
import Spinner from "../UI/Spinner";
import DOMPurify from "dompurify";
import "./styles.scss";
import PropTypes from "prop-types";

export const CONSENT_TO_TREATMENT = "CONSENT_TO_TREATMENT";
export const HIPAA = "HIPAA";
export const PRIVACY = "PRIVACY";
export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";

const Policy = (props) => {
    const { policyType, version } = props;
    const [content, setContent] = useState(undefined);
    const [contentVersion, setContentVersion] = useState(undefined);
    const [contentEffectiveDate, setContentEffectiveDate] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await getPolicy();
        })();
    }, []);

    // For non-mobile testing
    if (!window.ReactNativeWebView) {
        window.ReactNativeWebView = {
            postMessage: (message) => {
                console.log(message);
            },
        };
    }

    const getPolicy = async () => {
        try {
            const queryParams = { policy_type: policyType };
            if (version) {
                queryParams["version"] = version;
            }
            const response = await publicRequests.get_policy({ queryParams });
            const content = response.html_content;
            setContent(DOMPurify.sanitize(content));
            setContentVersion(response.version);
            setContentEffectiveDate(response.effective_date);
            setIsLoading(false);
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    error: false,
                    type: "success",
                    message: {
                        policyType,
                        version: response.version,
                    },
                }),
            );
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    error: true,
                    type: "error",
                    message: {
                        policyType,
                        version,
                    },
                }),
            );
        }
    };

    return isLoading ? (
        <Spinner />
    ) : (
        <div className={"wrap"}>
            {contentVersion && <div>[Version {contentVersion}]</div>}
            {contentEffectiveDate && <div>[EFFECTIVE DATE: {contentEffectiveDate}]</div>}
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
    );
};

export default Policy;

Policy.propTypes = {
    policyType: PropTypes.oneOf([CONSENT_TO_TREATMENT, HIPAA, PRIVACY, TERMS_AND_CONDITIONS])
        .isRequired,
    version: PropTypes.string,
};

Policy.defaultProps = {
    policyType: undefined,
    version: undefined,
};

// Copyright aptihealth, inc. 2019 All Rights Reserved

import * as yup from "yup";
import {
    noInsuranceCarrierValidationSchemaFactory,
    yup_password_factory,
} from "../../../utils/yupSchemaUtils";
import { adultRecommendChildProgramDOBValidationSchema } from "../../Dashboard/Providers/schema/newPatientValidation";

export const VALIDATION_SCHEMA = yup.object().shape({
    first_name: yup
        .string()
        .required("First Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    last_name: yup
        .string()
        .required("Last Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    dob: adultRecommendChildProgramDOBValidationSchema.required("Date of birth is required"),
    Username: yup.string().email("Enter a valid email address").required("Email is required"),
    Password: yup_password_factory(),
    ConfirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("Password"), null], "Password does not match"),
    gender: yup.string().required("Gender is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    address: yup.object().shape({
        street: yup.string().required("Street is required").nullable(),
        city: yup
            .string()
            .required("City is required")
            .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid City")
            .nullable(),
        state: yup.string().required("State is required").nullable(),
        zip_code: yup
            .string()
            .required("Zip code is required")
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
            .nullable(),
    }),
    insurance: yup.object().shape({
        carrier: yup.string().required("Insurance Company is required”"),
        member_id: noInsuranceCarrierValidationSchemaFactory(
            "carrier",
            yup
                .string()
                .required("Member ID is required")
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .nullable(),
        ),
    }),
    emergency_contact_person: yup.string().required("This is required").nullable(),
    emergency_contact_number: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required")
        .nullable(),
});

export const GUARDIANSHIP_VALIDATION_SCHEMA = yup.object().shape({
    first_name: yup
        .string()
        .required("First Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    last_name: yup
        .string()
        .required("Last Name is required")
        .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed"),
    dob: adultRecommendChildProgramDOBValidationSchema.required("Date of birth is required"),
    Username: yup.string().email("Enter a valid email address").required("Email is required"),
    Password: yup_password_factory(),
    ConfirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("Password"), null], "Password does not match"),
    gender: yup.string().required("Gender is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    address: yup.object().shape({
        street: yup.string().required("Street is required").nullable(),
        city: yup
            .string()
            .required("City is required")
            .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid City")
            .nullable(),
        state: yup.string().required("State is required").nullable(),
        zip_code: yup
            .string()
            .required("Zip code is required")
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
            .nullable(),
    }),
    insurance: yup.object().shape({
        carrier: yup.string(),
        member_id: noInsuranceCarrierValidationSchemaFactory(
            "carrier",
            yup
                .string()
                .when("carrier", (value, schema) =>
                    value !== undefined ? schema.required("Member ID is required") : schema,
                )
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .nullable(),
        ),
    }),
    emergency_contact_person: yup.string().required("This is required").nullable(),
    emergency_contact_number: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required")
        .nullable(),
});

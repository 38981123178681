import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../utils/filters";
import Policy, { TERMS_AND_CONDITIONS } from "../../components/Policy";
import React from "react";

const TermsAndConditionsPolicyPage = (props) => {
    const version = getQueryParams("version", props.location.search);

    return <Policy policyType={TERMS_AND_CONDITIONS} version={version} />;
};

export default withRouter(TermsAndConditionsPolicyPage);

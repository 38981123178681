import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "../../../../APIRequests";
import images from "../../../../utils/images";
import NoteActions from "../../../../components/Common/Note/NoteActions";
import GenericNoteView from "../../../../components/Common/Note/GenericNoteView";
import CardSecondary from "../../../../components/UI/Card/CardSecondary";
import DeleteNote from "../../Providers/PatientProfileView/DeleteNote";
import AccessingPHIDisclaimer from "../../../../components/Common/AccessingPHIDisclaimer";
import NoteAddendum from "./NoteAddendum";

import "./styles.scss";
import { MemberHeader } from "../../../../component-library/MemberHeader";
import { usePatient, usePatientDiagnosis } from "../../../../utils/hooks/usePatient";
import _ from "lodash";
import { calculateAge } from "../../../../utils/momentUtils";
import { formatMobileNumber } from "../../../../utils/filters";

const ViewFullNote = (props) => {
    const patientId = props.match.params.patientId;
    const history = useHistory();
    const patient = usePatient(patientId, history.location.state?.patientDetails);
    const diagnosis = usePatientDiagnosis(patientId, history.location.state?.patientDiagnosis);
    const primaryDiagnosis = _.get(diagnosis, "ICD_10_axis_1_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const secondaryDiagnosis = _.get(diagnosis, "ICD_10_axis_2_diagnosis", [])
        .map((icd_10) => icd_10["value"])
        .join(", ");
    const [note, setNote] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [showDeleteNotePopUp, setShowDeleteNotePopUp] = useState(false);
    const [draftNoteId, setDraftNoteId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [addendums, setAddendums] = useState([]);

    const getNote = async () => {
        try {
            let urlParams = { patientId };
            const queryParams = { note_id: props.match.params.noteId };
            let note = await api.shared.fetch_patient_progress_notes({ urlParams, queryParams });
            setNote(note);

            const data = {
                note_id: props.match.params.noteId,
                patient_id: patientId,
            };
            const addendumsResponse = await api.shared.fetch_note_addendums({ urlParams, data });
            setAddendums(addendumsResponse.addendums);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getNote();
    }, []);

    useEffect(() => {
        setIsEditable(isNoteEditable(note));
    }, [note, props.profile]);

    const isNoteEditable = (note) => {
        if (!note) {
            return false;
        }

        return note.note_state === "draft" && props.profile?.username === note.created_by;
    };

    const deleteNote = async (patientId, noteId) => {
        let data = { id: noteId };
        let urlParams = { patientId: patientId };
        await api.provider.delete_progress_note({ data, urlParams });
        setShowDeleteNotePopUp(false);
        goBack();
    };

    const goBack = () => {
        props.history.push(`/app/patient/${patientId}/profile/v2?selectedTab=Notes`);
    };

    return (
        <>
            <div className="survey-back-link-wpr mx-auto fs-16 my-2 p-3 pl-lg-0 pr-lg-0">
                <span className="survey-back-link" onClick={goBack}>
                    <img
                        className="img-fluid"
                        src={images("./common/solidBckBtn.svg")}
                        alt={"back"}
                    />
                    <p className="d-inline ml-2">Back to Patient Profile</p>
                </span>
            </div>

            {showDeleteNotePopUp && (
                <DeleteNote
                    clickHandler={() => deleteNote(patientId, draftNoteId)}
                    close={() => {
                        setShowDeleteNotePopUp(false);
                    }}
                />
            )}

            <CardSecondary className="mx-auto my-3 p-3">
                <div className={"row align-items-center"}>
                    <div className={"col-lg-8 col-12"}>
                        <div className={"pt-2"}>
                            <AccessingPHIDisclaimer />
                        </div>
                    </div>

                    <div className={"col-lg-4 col-12"}>
                        <NoteActions
                            note={note}
                            isEditable={isEditable}
                            patientId={patientId}
                            setShowDeleteNotePopUp={setShowDeleteNotePopUp}
                            setDraftNoteId={setDraftNoteId}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                        />
                    </div>
                </div>
            </CardSecondary>

            <div className={"d-flex justify-content-center"}>
                {patient && diagnosis && (
                    <MemberHeader
                        className={"cc-member-header"}
                        memberName={`${_.get(patient, "first_name", "")} ${_.get(
                            patient,
                            "last_name",
                            "",
                        )}`}
                        dob={_.get(patient, "dob")}
                        age={calculateAge(_.get(patient, "dob", ""))}
                        phoneNumber={formatMobileNumber(_.get(patient, "mobile"))}
                        dayOfCare={_.get(patient, "number_of_days_in_care", "")}
                        primaryDiagnosis={primaryDiagnosis === "" ? "None" : primaryDiagnosis}
                        secondaryDiagnosis={secondaryDiagnosis === "" ? "None" : secondaryDiagnosis}
                    />
                )}
            </div>

            <GenericNoteView
                patient={patient}
                patientName={patient && `${patient.first_name} ${patient.last_name}`}
                note={note}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                refetchNote={getNote}
            />

            {note?.note_state !== "draft" &&
                props.configs.NOTE_ADDENDUM &&
                addendums.map((addendum, index) => (
                    <NoteAddendum
                        className="row d-flex flex-wrap justify-content-between ml-auto mr-auto"
                        noteId={note?.id}
                        patientId={patientId}
                        addendum={addendum.addendum}
                        signature={addendum.signature}
                        credentials={addendum.credentials}
                        timestamp={addendum.timestamp}
                        textTestId={`note-addendum-text-${index}`}
                    />
                ))}
            {note?.created_by === props.profile?.username &&
                note?.note_state !== "draft" &&
                note?.note_type !== "Advanced Care Activity" &&
                props.configs.NOTE_ADDENDUM &&
                addendums.length < 5 && (
                    <NoteAddendum
                        className="row d-flex flex-wrap justify-content-between ml-auto mr-auto"
                        noteId={note?.id}
                        patientId={patientId}
                        signature={note?.signature}
                        credentials={note?.provider_cp_credentials}
                        onSubmit={(addendum) => setAddendums([...addendums, addendum])}
                    />
                )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        configs: state.configs,
    };
};

export default connect(mapStateToProps, {})(withRouter(ViewFullNote));

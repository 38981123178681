import React from "react";
import "./styles.scss";
import { Button, ButtonTypes, Card, CustomForm, Text } from "../../../../../component-library";
import { Form } from "formik";
import {
    ACTIVITY_REASON_OPTIONS,
    ACTIVITY_CLASS_OPTIONS,
    ACTIVITY_PURPOSE_OPTIONS,
    ACTIVITY_SEVICE_TYPE_OPTIONS,
    ADVANCED_CARE_ACTIVITY_VALIDATION,
    CARE_TEAM_MEMBER_TYPE_OPTIONS,
    COMMUNICATION_TYPE_OPTIONS,
    REASON_FOR_COMMUNICATION_OPTIONS,
    RECOMMENDED_NEXT_OPTIONS,
    YES_NO_OPTIONS,
    TIME_SLOT_OPTIONS,
    ADVANCED_CARE_ACTIVITY_CONTENT_FIELDS_TO_TRANSFORM_TO_BOOLEAN,
    INTENDED_DURATION_OPTIONS,
    filterDurationOptionsByLabel,
} from "./AdvancedCareActivityScheme";
import { FormSelect } from "../../../../../component-library/FormFields/FormSelect/FormSelect";
import { FormRadioGroup } from "../../../../../component-library/FormFields/FormRadioGroup/FormRadioGroup";
import { FormDateField } from "../../../../../component-library/FormFields/FormDateField/FormDateField";
import { FormTextInput } from "../../../../../component-library/FormFields/FormTextInput/FormTextInput";
import { FormTextArea } from "../../../../../component-library/FormFields/FormTextArea/FormTextArea";
import { FormMultiSelect } from "../../../../../component-library/FormFields/FormMultiSelect/FormMultiSelect";
import _ from "lodash";
import { CareManagementSignatureField } from "../CareManagement/CareManagementSignatureField";
import { CareManagementCredentialField } from "../CareManagement/CareManagementCredentialField";
import { filterTimePickerOptionsByLabel } from "../../../../../utils/calendar/dates";
import moment from "moment";
import { api } from "../../../../../APIRequests";
import { useHistory } from "react-router-dom";
import { FormFieldContainer } from "../../../../../component-library/FormFields/FormFieldContainer/FormFieldContainer";
import { AdvancedCareActivityTextInput } from "./AdvancedCareActivityInput";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const AdvancedCareActivityForm = ({ isEditing, noteState, patientId, nameToSign }) => {
    const history = useHistory();
    const preventEnterFormSubmission = (event) => {
        if (event.key === "Enter" || event.code === "Enter") {
            event.preventDefault();
        }
    };

    const renderForm = (formikProps) => {
        return (
            <Form onKeyDown={preventEnterFormSubmission} className="AdvancedCareActivityForm-form">
                <div className="AdvancedCareActivityForm-form_fields">
                    <FormSelect
                        className="AdvancedCareActivityForm-form_field"
                        dropDownClassName={
                            !isEditing && "AdvancedCareActivityForm-select_read_only"
                        }
                        disabled={!isEditing}
                        title="Advanced Care Service"
                        subTitle="Choose most relevant"
                        placeholder="Search Advanced Care Service"
                        name="content.activity_service_type"
                        options={ACTIVITY_SEVICE_TYPE_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        testId={"advanced-care-activity-assessment-activity-service-type-field"}
                    />

                    <div className="AdvancedCareActivityForm-form_field AdvancedCareActivityForm-additional_question_wrapper AdvancedCareActivityForm-activity_type_wrapper">
                        <FormMultiSelect
                            title="Activity Type"
                            dropDownClassName={
                                !isEditing && "AdvancedCareActivityForm-select_read_only"
                            }
                            disabled={!isEditing}
                            placeholder="Search Activity Type"
                            name="content.activity_purpose"
                            options={ACTIVITY_PURPOSE_OPTIONS}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            testId={"advanced-care-activity-assessment-activity-purpose-field"}
                        />

                        {formikProps.values.content.activity_purpose.includes("Other") && (
                            <AdvancedCareActivityTextInput
                                title="Specify Other"
                                name="content.activity_purpose_other_details"
                                readOnly={!isEditing}
                                formikProps={formikProps}
                                triggerErrorOnChange
                                testId={
                                    "advanced-care-activity-assessment-activity-purpose-other-field"
                                }
                            />
                        )}
                    </div>

                    <FormSelect
                        className="AdvancedCareActivityForm-form_field"
                        dropDownClassName={
                            !isEditing && "AdvancedCareActivityForm-select_read_only"
                        }
                        disabled={!isEditing}
                        title="Intended Duration"
                        subTitle="Anticipated duration of the activity"
                        name="content.intended_duration"
                        filterOptions={filterDurationOptionsByLabel}
                        options={INTENDED_DURATION_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        testId={"advanced-care-activity-assessment-duration-field"}
                    />

                    <FormRadioGroup
                        className="AdvancedCareActivityForm-form_field"
                        title="Activity Location"
                        name="service_location"
                        options={ACTIVITY_CLASS_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        disabled={!isEditing}
                        radioContainerClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_group_read_only"
                        }
                        radioButtonClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_button_read_only"
                        }
                        radioLabelClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_label_read_only"
                        }
                        testId={"advanced-care-activity-assessment-location-field"}
                    />

                    <div className="AdvancedCareActivityForm-form_field AdvancedCareActivityForm-additional_question_wrapper">
                        <FormSelect
                            dropDownClassName={
                                !isEditing && "AdvancedCareActivityForm-select_read_only"
                            }
                            disabled={!isEditing}
                            title="Communication Method"
                            placeholder="Search Communication Method"
                            name="content.communication_type"
                            options={COMMUNICATION_TYPE_OPTIONS}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            testId={"advanced-care-activity-assessment-communication-type-field"}
                        />
                        {formikProps.values.content.communication_type === "Other" && (
                            <AdvancedCareActivityTextInput
                                title="Specify Other"
                                name="content.communication_type_other_details"
                                readOnly={!isEditing}
                                formikProps={formikProps}
                                triggerErrorOnChange
                                testId={
                                    "advanced-care-activity-assessment-communication-type-other-field"
                                }
                            />
                        )}
                    </div>

                    <FormRadioGroup
                        className="AdvancedCareActivityForm-form_field"
                        title="Was the activity planned?"
                        name="content.activity_reason"
                        options={ACTIVITY_REASON_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        disabled={!isEditing}
                        radioContainerClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_group_read_only"
                        }
                        radioButtonClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_button_read_only"
                        }
                        radioLabelClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_label_read_only"
                        }
                        testId={"advanced-care-activity-assessment-activity-reason-field"}
                    />

                    <FormRadioGroup
                        className="AdvancedCareActivityForm-form_field"
                        title="Was the patient expected to be present?"
                        name="content.patient_encounter"
                        options={YES_NO_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        disabled={!isEditing}
                        radioContainerClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_group_read_only"
                        }
                        radioButtonClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_button_read_only"
                        }
                        radioLabelClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_label_read_only"
                        }
                        testId={"advanced-care-activity-assessment-encounter-field"}
                    />

                    <FormRadioGroup
                        className="AdvancedCareActivityForm-form_field"
                        title="Did the patient attend?"
                        name="content.patient_participant_attest"
                        options={YES_NO_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        disabled={!isEditing}
                        radioContainerClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_group_read_only"
                        }
                        radioButtonClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_button_read_only"
                        }
                        radioLabelClassName={
                            !isEditing && "AdvancedCareActivityForm-radio_label_read_only"
                        }
                        testId={"advanced-care-activity-assessment-attend-field"}
                    />
                    <FormDateField
                        className="AdvancedCareActivityForm-form_field"
                        title="Activity Date"
                        placeholder="MM/DD/YYYY"
                        name="session_date"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        inputClassName={!isEditing && "AdvancedCareActivityForm-input_read_only"}
                        disabled={!isEditing}
                        {...getAutomationDataAttr("advanced-care-activity-assessment-date-field")}
                    />

                    <FormSelect
                        className="AdvancedCareActivityForm-form_field"
                        dropDownClassName={
                            !isEditing && "AdvancedCareActivityForm-select_read_only"
                        }
                        disabled={!isEditing}
                        title="Start Time"
                        placeholder="HH:MM"
                        name="session_from"
                        options={TIME_SLOT_OPTIONS}
                        filterOptions={filterTimePickerOptionsByLabel}
                        formikProps={formikProps}
                        otherErrorNames={["time_error", "start_date_time_error"]}
                        triggerErrorOnChange
                        testId={"advanced-care-activity-assessment-start-field"}
                    />

                    <FormSelect
                        className="AdvancedCareActivityForm-form_field"
                        dropDownClassName={
                            !isEditing && "AdvancedCareActivityForm-select_read_only"
                        }
                        disabled={!isEditing}
                        title="End Time"
                        placeholder="HH:MM"
                        name="session_to"
                        options={TIME_SLOT_OPTIONS}
                        filterOptions={filterTimePickerOptionsByLabel}
                        formikProps={formikProps}
                        otherErrorNames={["time_error", "end_date_time_error"]}
                        triggerErrorOnChange
                        testId={"advanced-care-activity-assessment-end-field"}
                    />

                    <FormMultiSelect
                        className="AdvancedCareActivityForm-form_field"
                        dropDownClassName={
                            !isEditing && "AdvancedCareActivityForm-select_read_only"
                        }
                        disabled={!isEditing}
                        title="Reason for Communication"
                        placeholder="Search Reason for Communication"
                        name="content.reason_for_communication"
                        options={REASON_FOR_COMMUNICATION_OPTIONS}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        testId={"advanced-care-activity-assessment-reason-for-communication-field"}
                    />

                    <div className="AdvancedCareActivityForm-form_field AdvancedCareActivityForm-additional_question_wrapper">
                        <FormRadioGroup
                            className={
                                !isEditing && "AdvancedCareActivityForm-radio_group_read_only"
                            }
                            title="Was the activity completed as planned?"
                            name="content.activity_result"
                            options={YES_NO_OPTIONS}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            disabled={!isEditing}
                            radioContainerClassName={
                                !isEditing && "AdvancedCareActivityForm-radio_group_read_only"
                            }
                            radioButtonClassName={
                                !isEditing && "AdvancedCareActivityForm-radio_button_read_only"
                            }
                            radioLabelClassName={
                                !isEditing && "AdvancedCareActivityForm-radio_label_read_only"
                            }
                            testId={"advanced-care-activity-assessment-result-field"}
                        />

                        {formikProps.values.content.activity_result === "No" && (
                            <AdvancedCareActivityTextInput
                                className="AdvancedCareActivityForm-form_field"
                                title="Why was the activity not completed as planned?"
                                name="content.activity_result_note"
                                formikProps={formikProps}
                                triggerErrorOnChange
                                readOnly={!isEditing}
                                testId={"advanced-care-activity-assessment-result-note-field"}
                            />
                        )}
                    </div>

                    <div className="AdvancedCareActivityForm-form_field AdvancedCareActivityForm-additional_question_wrapper">
                        <FormMultiSelect
                            dropDownClassName={
                                !isEditing && "AdvancedCareActivityForm-select_read_only"
                            }
                            disabled={!isEditing}
                            title="Care Team Follow Up"
                            placeholder="Search Care team follow up"
                            name="content.recommended_next"
                            options={RECOMMENDED_NEXT_OPTIONS}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            testId={"advanced-care-activity-assessment-recommended-next-field"}
                        />
                        {formikProps.values.content.recommended_next.includes("Other") && (
                            <AdvancedCareActivityTextInput
                                title="Specify Other"
                                name="content.recommended_next_other_details"
                                formikProps={formikProps}
                                triggerErrorOnChange
                                readOnly={!isEditing}
                                testId={
                                    "advanced-care-activity-assessment-recommended-next-other-field"
                                }
                            />
                        )}
                    </div>

                    {!isEditing && (
                        <FormFieldContainer
                            className="AdvancedCareActivityForm-summary_form_field"
                            title="Summary"
                            subTitle="Highlight interventions and patient reactions">
                            <Text
                                className="AdvancedCareActivityForm-text_area_read_only"
                                testId={"advanced-care-activity-assessment-summary-field"}>
                                {formikProps.values.content.additional_information}
                            </Text>
                        </FormFieldContainer>
                    )}

                    {isEditing && (
                        <FormTextArea
                            className="AdvancedCareActivityForm-summary_form_field"
                            inputClassName="AdvancedCareActivityForm-summary_form_field_input"
                            title="Summary"
                            subTitle="Highlight interventions and patient reactions"
                            name="content.additional_information"
                            formikProps={formikProps}
                            triggerErrorOnChange
                            readOnly={!isEditing}
                            testId={"advanced-care-activity-assessment-summary-field"}
                        />
                    )}

                    <div className="AdvancedCareActivityForm-form_field AdvancedCareActivityForm-additional_question_wrapper">
                        <FormSelect
                            dropDownClassName={
                                !isEditing && "AdvancedCareActivityForm-select_read_only"
                            }
                            disabled={!isEditing}
                            title="Your Role"
                            placeholder="Search Role"
                            name="content.care_team_member_type"
                            options={CARE_TEAM_MEMBER_TYPE_OPTIONS}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            testId={"advanced-care-activity-assessment-role-field"}
                        />
                        {formikProps.values.content.care_team_member_type === "Other" && (
                            <AdvancedCareActivityTextInput
                                title="Specify Other"
                                name="content.care_team_member_type_other_details"
                                formikProps={formikProps}
                                triggerErrorOnChange
                                readOnly={!isEditing}
                                testId={"advanced-care-activity-assessment-role-other-field"}
                            />
                        )}
                    </div>

                    {/* Copied from CareManagementForm  */}
                    {isEditing && (
                        <div className="AdvancedCareActivityForm-signature_section">
                            <CareManagementSignatureField
                                formikProps={formikProps}
                                isViewOnly={false}
                                nameToSign={nameToSign}
                                name="signature"
                                testId={"advanced-care-activity-assessment-signature-field"}
                            />
                            {formikProps.values["provider_cp_credentials"] && (
                                <CareManagementCredentialField
                                    formikProps={formikProps}
                                    name="provider_cp_credentials"
                                    isViewOnly={false}
                                />
                            )}
                        </div>
                    )}

                    {!isEditing && (
                        <div className="AdvancedCareActivityForm-view_only_signature_section">
                            <CareManagementSignatureField
                                formikProps={formikProps}
                                isViewOnly={true}
                                nameToSign={nameToSign}
                                name="signature"
                                testId={"advanced-care-activity-assessment-signature-field"}
                            />
                            <FormTextInput
                                formikProps={formikProps}
                                title="Signature Date"
                                name="signature_date"
                                readOnly={!isEditing}
                                inputClassName={
                                    !isEditing && "AdvancedCareActivityForm-input_read_only"
                                }
                                testId={"advanced-care-activity-assessment-signature-date-field"}
                            />
                            {formikProps.values["provider_cp_credentials"] && (
                                <CareManagementCredentialField
                                    formikProps={formikProps}
                                    name="provider_cp_credentials"
                                    isViewOnly={true}
                                    testId={"advanced-care-activity-assessment-credentials-field"}
                                />
                            )}
                        </div>
                    )}
                </div>

                {isEditing && (
                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            disabled={
                                !formikProps.dirty ||
                                Object.keys(formikProps.errors).length ||
                                !patientId ||
                                !nameToSign
                            }
                            type="submit"
                            buttonType={ButtonTypes.primaryV2}
                            {...getAutomationDataAttr(
                                "advanced-care-activity-assessment-submit-button",
                            )}>
                            Save Assessment
                        </Button>
                    </div>
                )}
            </Form>
        );
    };

    return (
        <Card className="AdvancedCareActivityForm-content_card">
            <h1
                className="AdvancedCareActivityForm-title"
                {...getAutomationDataAttr("advanced-care-activity-assessment-title")}>
                Advanced Care Activity Assessment
            </h1>

            {noteState && (
                <CustomForm
                    initialValues={noteState}
                    validationSchema={ADVANCED_CARE_ACTIVITY_VALIDATION}
                    validateOnChange={true}
                    validateOnBlur={true}
                    render={renderForm}
                    onSubmit={async (formData) => {
                        const copyOfFormData = _.cloneDeep(formData);

                        // if the selected value can have a free text field but the free
                        // text option is not selected and there is stale state, remove it
                        // from submission
                        if (
                            !copyOfFormData.content.activity_purpose.includes("Other") &&
                            copyOfFormData.content.activity_purpose_other_details
                        ) {
                            copyOfFormData.content.activity_purpose_other_details = "";
                        }

                        if (
                            copyOfFormData.content.communication_type !== "Other" &&
                            copyOfFormData.content.communication_type_other_details
                        ) {
                            copyOfFormData.content.communication_type_other_details = "";
                        }

                        if (
                            copyOfFormData.content.care_team_member_type !== "Other" &&
                            copyOfFormData.content.care_team_member_type_other_details
                        ) {
                            copyOfFormData.content.care_team_member_type_other_details = "";
                        }

                        if (
                            copyOfFormData.content.activity_result !== "No" &&
                            copyOfFormData.content.activity_result_note
                        ) {
                            copyOfFormData.content.activity_result_note = "";
                        }

                        if (
                            !copyOfFormData.content.recommended_next.includes("Other") &&
                            copyOfFormData.content.recommended_next_other_details
                        ) {
                            copyOfFormData.content.recommended_next_other_details = "";
                        }

                        ADVANCED_CARE_ACTIVITY_CONTENT_FIELDS_TO_TRANSFORM_TO_BOOLEAN.forEach(
                            (item) => {
                                const foundOption = YES_NO_OPTIONS.find(
                                    (option) => option.value === copyOfFormData.content[item],
                                );

                                copyOfFormData.content[item] = foundOption.booleanValue;
                            },
                        );

                        copyOfFormData["session_date"] = moment(
                            copyOfFormData["session_date"],
                            "MMDDYYYY",
                        ).format("MM/DD/YYYY");

                        copyOfFormData["content"]["intended_duration"] = Number(
                            copyOfFormData["content"]["intended_duration"],
                        );

                        copyOfFormData["completed_date_time"] = moment().format();

                        const urlParams = { patientId };
                        await api.provider.post_progress_note({ urlParams, data: copyOfFormData });
                        history.push(`/app/patient/${patientId}/profile/v2?selectedTab=Notes`);
                    }}
                />
            )}
        </Card>
    );
};

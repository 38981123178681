import React, { useEffect, useState } from "react";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../UI/ListView";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment/moment";
import momentTZ from "moment-timezone";
import "./style.scss";
import { DownloadIcon, Text, TextColors, TextTypes } from "../../../../component-library";
import { ICON_SIZES } from "../../../../constants/icons";
import { viewPatientPolicyFile } from "../../../../utils/fileUtil";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

const MemberProfilePolicyList = (props) => {
    const { consents, patientId } = props;
    const [consentData, setConsentData] = useState([]);

    const getPatientConsent = async () => {
        const sortedConsentData = consents
            .filter((consent) => consent?.consent_form_version != null)
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setConsentData(sortedConsentData);
    };

    useEffect(() => {
        (async () => {
            await getPatientConsent();
        })();
    }, []);

    const getPolicyFriendlyName = (consentFormName) => {
        const policyFriendlyNames = {
            CONSENT_TO_TREATMENT: "Consent to Treatment",
            HIPAA: "HIPAA Notice of Privacy Practices",
            TERMS_AND_CONDITIONS: "Terms and Conditions of Use",
            PRIVACY: "Privacy Policy",
        };
        return policyFriendlyNames[consentFormName];
    };

    const buildListItemData = (consent) => {
        return {
            policyName: getPolicyFriendlyName(consent["consent_form_name"]),
            effectiveDate: consent["consent_form_effective_date"],
            consentGivenBy: consent["response_given_by_name"],
            dateAgreed: moment
                .utc(consent.timestamp)
                .tz(momentTZ.tz.guess())
                .format("M/D/YYYY hh:mm a"),
            fileKey: consent["user_file_report_key"],
            fileSubType: consent["consent_form_name"],
        };
    };

    const downloadIcon = (listItemData) => {
        let testId = "patient-policy-list-item-download";
        try {
            testId += `-${listItemData.fileSubType.toLowerCase().replaceAll("_", "-")}`;
        } catch (e) {
            console.log(e);
        }
        return (
            <DownloadIcon
                {...getAutomationDataAttr(testId)}
                iconSize={ICON_SIZES.SMALL}
                className={"patient-policy-list-download-icon"}
                onClick={() =>
                    viewPatientPolicyFile(patientId, listItemData.fileKey, listItemData.fileSubType)
                }
            />
        );
    };

    const desktopListItem = (consent) => {
        const listItemData = buildListItemData(consent);
        return (
            <div
                className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between"
                {...getAutomationDataAttr("patient-policy-list-item")}>
                <span className="col-5">{listItemData.policyName}</span>
                <span className="col-2">{listItemData.effectiveDate}</span>
                <span className="col-2">{listItemData.consentGivenBy}</span>
                <span className="col-2">{listItemData.dateAgreed}</span>
                <span className="col-1">{listItemData.fileKey && downloadIcon(listItemData)}</span>
            </div>
        );
    };

    const mobileListItem = (consent) => {
        const listItemData = buildListItemData(consent);
        return (
            <div className="d-flex d-lg-none justify-content-between">
                <div className="d-flex fs-14 flex-column" style={{ wordWrap: "break-word" }}>
                    <p className={"fw-bold"}>{listItemData.policyName}</p>
                    <p>Effective Date: {listItemData.effectiveDate}</p>
                    <p>Consent Given By: {listItemData.consentGivenBy}</p>
                    <p>Date Agreed: {listItemData.dateAgreed}</p>
                    {listItemData.fileKey && <p>Download: {downloadIcon(listItemData)}</p>}
                </div>
            </div>
        );
    };

    const renderConsentList = () => {
        return (
            <ListBody classes="List__body_lg patient-policy-list my-3 patient-policy-list">
                <ListHeader
                    title={"Patient Policies"}
                    className={"patient-policy-list-title-header"}
                />
                {consentData?.length > 0 && (
                    <div {...getAutomationDataAttr("patient-policy-list-items-exist")}>
                        <ListTitleBar className={"patient-policy-list-title-bar"}>
                            <TitleBarItem
                                classes="col-5 patient-policy-list-title-bar-col-header"
                                text="Policy Name"
                            />
                            <TitleBarItem
                                classes="col-2 patient-policy-list-title-bar-col-header"
                                text="Effective Date"
                            />
                            <TitleBarItem
                                classes="col-2 patient-policy-list-title-bar-col-header"
                                text="Consent Given By"
                            />
                            <TitleBarItem
                                classes="col-2 patient-policy-list-title-bar-col-header"
                                text="Date Agreed"
                            />
                            <TitleBarItem
                                classes="col-1 patient-policy-list-title-bar-col-header"
                                text=""
                            />
                        </ListTitleBar>
                        <List>
                            {consentData.map((consent) => {
                                return (
                                    <ListItem
                                        key={consent.sort_key}
                                        testId={"patient-policy-list-item"}>
                                        {desktopListItem(consent)}
                                        {mobileListItem(consent)}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                )}
                {consentData?.length === 0 && (
                    <div
                        className={"py-4 px-lg-3 mx-lg-3"}
                        {...getAutomationDataAttr("patient-policy-list-no-items-exist")}>
                        <Text type={TextTypes.light} color={TextColors.grey}>
                            No patient policies currently on file
                        </Text>
                    </div>
                )}
            </ListBody>
        );
    };

    return <div {...getAutomationDataAttr("patient-policy-list")}>{renderConsentList()}</div>;
};

MemberProfilePolicyList.propTypes = {
    patientDetails: PropTypes.object,
    profile: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(MemberProfilePolicyList);

import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { CareManagementSectionTitle } from "./CareManagementSectionTitle";
import { Button, ButtonTypes, Card, CustomForm } from "../../../../../component-library";
import { CareManagementField } from "./CareManagementField";
import { CareManagementRadioField } from "./CareManagementRadioField";
import { CareManagementRatingFields } from "./CareManagementRatingFields";
import { CareManagementSignatureField } from "./CareManagementSignatureField";
import { Form } from "formik";
import {
    CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_OPTIONS,
    CARE_MANAGEMENT_VALIDATION,
    DO_OTHERS_HELP_WITH_LIFE_ACTIVITIES_OPTIONS,
    HELP_TO_IMPROVE_LIFE_OPTIONS,
} from "./CaseManagementFormScheme";
import { CareManagementCredentialField } from "./CareManagementCredentialField";
import { api } from "../../../../../APIRequests";
import moment from "moment/moment";
import _ from "lodash";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const CareManagementForm = ({
    isEditing,
    noteState,
    patientId,
    nameToSign,
    setIsEditing,
    refetchNote,
}) => {
    const history = useHistory();
    // used to indicate if everything has loaded
    const disableSubmit = !patientId || !nameToSign;

    /**
     * attach common fields to form
     * session_date is used for indexing even though it
     * does not make sense in this context
     * @param {*} formData
     * @returns
     */
    const prepareNoteForSubmission = (formData) => {
        const clonedFormData = _.cloneDeep(formData);
        clonedFormData["session_date"] = moment().format("MM/DD/YYYY");
        return clonedFormData;
    };

    const submitNote = async (formData) => {
        const urlParams = { patientId };
        if (noteState.note_state === "draft" && formData.note_state === "complete") {
            await api.provider.put_progress_note({ urlParams, data: formData });
            history.push(`/app/patient/${patientId}/profile/v2?selectedTab=Notes`);
        } else if (noteState.note_state === "draft") {
            await api.provider.put_progress_note({ urlParams, data: formData });
        } else {
            await api.provider.post_progress_note({ urlParams, data: formData });
            history.push(`/app/patient/${patientId}/profile/v2?selectedTab=Notes`);
        }
    };

    const preventEnterFormSubmission = (event) => {
        if (event.key === "Enter" || event.code === "Enter") {
            event.preventDefault();
        }
    };

    const renderForm = (formikProps) => {
        const signature_date = formikProps.values["signature_date"];
        // reset the form when it enters view only mode and
        //  is dirty or the note has been updated and the form values need
        //  to be refreshed
        if (!isEditing && (formikProps.dirty || !_.isEqual(noteState, formikProps.values))) {
            formikProps.values = noteState;
            formikProps.resetForm(formikProps);
        }

        return (
            <Form onKeyDown={preventEnterFormSubmission}>
                <Card className="CareManagementForm--content_card">
                    <h1
                        className="CareManagementForm--title"
                        {...getAutomationDataAttr("care-management-assessment-title")}>
                        Care Management Assessment
                    </h1>
                    <div className="CareManagementForm--section">
                        <CareManagementSectionTitle>Review A5, A30, DI</CareManagementSectionTitle>
                        <CareManagementField
                            title={"Evaluation"}
                            subTitle={
                                "Review documents (A5, A30, DI, notes, etc.) for salient information. Ask about problems and help needed."
                            }
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.review.evaluation"}
                            testId={"care-management-assessment-evaluation-field"}
                        />

                        <CareManagementField
                            title={"Understanding of diagnosis"}
                            subTitle={
                                "What is the diagnosis and why? How does it affect their life? How have they coped?"
                            }
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.review.understanding_of_diagnosis"}
                            testId={"care-management-assessment-understanding-diagnosis-field"}
                        />

                        <CareManagementField
                            title={"Difficulties navigating the health system"}
                            subTitle={
                                "Focus on attempts to get help, approach used, and outcomes experienced."
                            }
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.review.difficulties_navigating_health_system"}
                            testId={"care-management-assessment-navigating-health-system-field"}
                        />

                        <CareManagementRadioField
                            title={
                                "Can the patient be assisted with the creation of life-planning activities?"
                            }
                            subTitle={"E.g. living will, DNR, etc."}
                            radioOptions={CAN_BE_ASSISTED_WITH_LIFE_PLANNING_ACTIVITIES_OPTIONS}
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.review.can_be_assisted_with_life_planning_activities"}
                            testId={"care-management-assessment-life-planning-activities-field"}
                        />
                    </div>
                    <div className="CareManagementForm--section">
                        <CareManagementSectionTitle>Cultural Impact</CareManagementSectionTitle>

                        <CareManagementField
                            title={"Family or cultural views"}
                            subTitle={
                                "Describe views of how one should live, deal with illness & problems, what happens when one dies, and stigmas."
                            }
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.cultural_impact.family_cultural_views"}
                            testId={"care-management-assessment-family-cultural-views-field"}
                        />

                        <CareManagementField
                            title={"Health care approach discouraged"}
                            subTitle={
                                "Describe cultural or religious restrictions to getting help."
                            }
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.cultural_impact.health_care_approach_discouraged"}
                            testId={"care-management-assessment-health-care-discouraged-field"}
                        />
                    </div>
                    <div className="CareManagementForm--section">
                        <CareManagementSectionTitle>Care Resources</CareManagementSectionTitle>

                        <CareManagementRadioField
                            title={"How much help does patient need to improve life?"}
                            radioOptions={HELP_TO_IMPROVE_LIFE_OPTIONS}
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.care_resources.help_to_improve_life"}
                            testId={"care-management-assessment-help-to-improve-life-field"}
                        />

                        <CareManagementRadioField
                            title={"Do family, friends, or hired help assist with life activities?"}
                            radioOptions={DO_OTHERS_HELP_WITH_LIFE_ACTIVITIES_OPTIONS}
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.care_resources.do_others_help_with_life_activities"}
                            testId={"care-management-assessment-others-help-life-field"}
                        />
                        <CareManagementField
                            title={"Elaborate on assistance needed and caregiver help"}
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.care_resources.elaborate_assistance_needed"}
                            testId={"care-management-assessment-assistance-needed-field"}
                        />

                        <CareManagementField
                            title={"Life-improving goals"}
                            subTitle={
                                "What changes would make the person happy with their long-term life goals?"
                            }
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.care_resources.life_improving_goals"}
                            testId={"care-management-assessment-life-goals-field"}
                        />
                    </div>
                    <div className="CareManagementForm--section">
                        <CareManagementSectionTitle>Conclusion</CareManagementSectionTitle>

                        <div className="CareManagementFieldShared--wrapper">
                            <div className="CareManagementFieldShared--text_wrapper">
                                <h3 className="CareManagementFieldShared--title">
                                    {"Care management focus"}
                                </h3>
                                <h3 className="CareManagementFieldShared--subTitle">
                                    {
                                        "How severe is the impact of each item? Discuss and reconcile any items that the clinician rates differently."
                                    }
                                </h3>
                            </div>
                            <div className="CareManagementForm--cm_focus_questions">
                                <CareManagementRatingFields
                                    title={"Tier 1"}
                                    omitLine
                                    subTitle={"Immediate action"}
                                    testId={"care-management-assessment-conclusion-tier-1"}
                                    questions={[
                                        {
                                            title: "Risk of violence",
                                            name: "content.conclusion.tier_1.risk_of_violence",
                                        },
                                        {
                                            title: "Homelessness or urgent housing insecurity",
                                            name: "content.conclusion.tier_1.homelessness_or_housing_insecurity",
                                        },
                                        {
                                            title: "Shelter",
                                            name: "content.conclusion.tier_1.shelter",
                                        },
                                        {
                                            title: "Health Emergency",
                                            name: "content.conclusion.tier_1.health_emergency",
                                        },
                                        {
                                            title: "Emergency food needs",
                                            name: "content.conclusion.tier_1.emergency_food_needs",
                                        },
                                        { title: "Other", name: "content.conclusion.tier_1.other" },
                                    ]}
                                    formikProps={formikProps}
                                    isViewOnly={!isEditing}
                                />

                                <CareManagementRatingFields
                                    title={"Tier 2"}
                                    subTitle={"Action in the next few days"}
                                    testId={"care-management-assessment-conclusion-tier-2"}
                                    questions={[
                                        {
                                            title: "Urgent health condition(s)",
                                            name: "content.conclusion.tier_2.urgent_health_conditions",
                                        },
                                        {
                                            title: "No access to medical",
                                            name: "content.conclusion.tier_2.no_access_medical",
                                        },
                                        {
                                            title: "No access to food",
                                            name: "content.conclusion.tier_2.no_access_food",
                                        },
                                        {
                                            title: "Recent admission or ED visit with follow up noncompliance",
                                            name: "content.conclusion.tier_2.recent_admission_or_ed",
                                        },
                                        { title: "Other", name: "content.conclusion.tier_2.other" },
                                    ]}
                                    formikProps={formikProps}
                                    isViewOnly={!isEditing}
                                />

                                <CareManagementRatingFields
                                    title={"Tier 3"}
                                    subTitle={"Action in the next few weeks"}
                                    testId={"care-management-assessment-conclusion-tier-3"}
                                    questions={[
                                        {
                                            title: "Chronic medical condition",
                                            name: "content.conclusion.tier_3.chronic_condition",
                                        },
                                        {
                                            title: "Home goods / furniture",
                                            name: "content.conclusion.tier_3.home_goods",
                                        },
                                        { title: "ADLs", name: "content.conclusion.tier_3.adls" },
                                        {
                                            title: "Financial",
                                            name: "content.conclusion.tier_3.financial",
                                        },
                                        {
                                            title: "Relationships / social",
                                            name: "content.conclusion.tier_3.relationships_social",
                                        },
                                        {
                                            title: "Legal problems",
                                            name: "content.conclusion.tier_3.legal_problems",
                                        },
                                        {
                                            title: "Leisure",
                                            name: "content.conclusion.tier_3.leisure",
                                        },
                                        {
                                            title: "Educational",
                                            name: "content.conclusion.tier_3.educational",
                                        },
                                        {
                                            title: "Employment",
                                            name: "content.conclusion.tier_3.employment",
                                        },
                                        {
                                            title: "Limitations",
                                            name: "content.conclusion.tier_3.limitations",
                                        },
                                        { title: "Other", name: "content.conclusion.tier_3.other" },
                                    ]}
                                    formikProps={formikProps}
                                    isViewOnly={!isEditing}
                                />
                            </div>
                        </div>

                        <CareManagementField
                            title={"Summary"}
                            subTitle={"What needs to be done to help the patient?"}
                            formikProps={formikProps}
                            isViewOnly={!isEditing}
                            name={"content.conclusion.summary"}
                            testId={"care-management-assessment-summary-field"}
                        />
                    </div>

                    <div className="CareManagementForm--section">
                        <CareManagementSectionTitle />
                        {isEditing && (
                            <div className="CareManagementForm--signature_section">
                                <CareManagementSignatureField
                                    formikProps={formikProps}
                                    isViewOnly={false}
                                    nameToSign={nameToSign}
                                    name={"signature"}
                                    testId={"care-management-assessment-signature-field"}
                                />
                                <CareManagementCredentialField
                                    formikProps={formikProps}
                                    isViewOnly={false}
                                    name={"provider_cp_credentials"}
                                />
                            </div>
                        )}
                        {!isEditing && (
                            <div className="CareManagementForm--view_only_signature_section">
                                <CareManagementSignatureField
                                    formikProps={formikProps}
                                    isViewOnly={true}
                                    nameToSign={nameToSign}
                                    name={"signature"}
                                    testId={"care-management-assessment-signature-field"}
                                />
                                <div className="CareManagementSignatureField--view_only_wrapper">
                                    <p className="CareManagementFieldShared--title">
                                        Signature Date
                                    </p>
                                    <p
                                        className="CareManagementField--view_only_input"
                                        {...getAutomationDataAttr(
                                            "care-management-assessment-signature-date-field",
                                        )}>
                                        {signature_date === "" ? "None" : signature_date}
                                    </p>
                                </div>

                                <CareManagementCredentialField
                                    formikProps={formikProps}
                                    name={"provider_cp_credentials"}
                                    isViewOnly={true}
                                    testId={"care-management-assessment-credentials-field"}
                                />
                            </div>
                        )}
                    </div>
                    {/* only render submit buttons if editing the form  */}
                    {isEditing && (
                        <div className="CareManagementForm--button_wrapper">
                            <Button
                                disabled={disableSubmit}
                                type="submit"
                                buttonType={ButtonTypes.primaryV2}
                                {...getAutomationDataAttr(
                                    "care-management-assessment-submit-button",
                                )}>
                                Save Assessment
                            </Button>
                            <Button
                                disabled={disableSubmit}
                                className={"CareManagementForm--save_draft_button"}
                                onClick={async () => {
                                    const formData = formikProps.values;
                                    const clonedFormData = prepareNoteForSubmission(formData);
                                    clonedFormData["note_state"] = "draft";
                                    await submitNote(clonedFormData);
                                    if (noteState?.note_state) {
                                        await refetchNote();
                                        setIsEditing(false);
                                    }
                                }}
                                buttonType={ButtonTypes.primaryOutlineV2}
                                {...getAutomationDataAttr(
                                    "care-management-assessment-save-draft-button",
                                )}>
                                {noteState?.note_state === "draft" ? "Update Draft" : "Save Draft"}
                            </Button>
                            {noteState?.note_state === "draft" && isEditing && (
                                <Button
                                    disabled={disableSubmit}
                                    className={"CareManagementForm--discard_changes_button"}
                                    onClick={() => {
                                        // form reset will happen after this state propagates
                                        setIsEditing(false);
                                    }}
                                    buttonType={ButtonTypes.primaryOutlineV2}
                                    {...getAutomationDataAttr(
                                        "care-management-assessment-discard-changes-button",
                                    )}>
                                    Discard Changes
                                </Button>
                            )}
                        </div>
                    )}
                </Card>
            </Form>
        );
    };

    return (
        <>
            {noteState && (
                <CustomForm
                    initialValues={noteState}
                    validationSchema={CARE_MANAGEMENT_VALIDATION}
                    validateOnChange={true}
                    validateOnBlur={true}
                    render={renderForm}
                    onSubmit={async (formData) => {
                        const clonedFormData = prepareNoteForSubmission(formData);
                        clonedFormData["completed_date_time"] = moment().format();
                        clonedFormData["note_state"] = "complete";
                        await submitNote(clonedFormData);
                    }}
                />
            )}
        </>
    );
};

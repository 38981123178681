// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect } from "react";
import "./styles.scss";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import { trackEvent } from "../../../utils/EventTrackingUtil";
import { connect } from "react-redux";

const ProgramDetails = (props) => {
    useEffect(() => {
        trackEvent("Child Program Details");
    }, []);

    return (
        <>
            <div
                className={"d-flex program-details-hero program-details-container"}
                data-public={"true"}>
                <div className={"program-details-hero-text-container"}>
                    <div className={"txt-pri fw-bold proxima program-details-hero-text"}>
                        <div>Your Child’s</div>
                        <div>Fast Track to</div>
                        <div>Better Mental Health</div>
                    </div>
                </div>
                <div className={"program-details-hero-img"}></div>
            </div>

            <div className={"txt-gry program-details-container"} data-public={"true"}>
                <div className={"text-center fs-18 py-3"}>
                    <div className={"mb-4"}>
                        Thank you for choosing <span className={"fw-bold"}>aptihealth</span> for
                        your child’s mental healthcare needs.
                    </div>
                    <div className={"mb-4"}>
                        To register your child for therapy and medication management with
                        aptihealth, you’ll need to create a separate account for them. A separate
                        account allows your child the freedom to message directly with their
                        therapist and feel empowered to manage their well-being.
                    </div>
                    <div className={"mb-4"}>
                        Once you register your child, they will receive an email prompting them to
                        set a password and complete registration. They must complete this step
                        before their first consultation, as all their online sessions will take
                        place in their own account.
                    </div>
                </div>

                <div className={"d-md-flex pb-5"}>
                    <div className={"program-details-icon-container"}>
                        <img
                            src={images("./patient/teens_and_kids_program/session.svg")}
                            alt={"general info"}
                            className={"program-details-icon"}
                        />
                    </div>
                    <div className={"ml-4"}>
                        <div className={"txt-pri fw-bold fs-16 mb-2"}>
                            Teens + Kids Care Program
                        </div>
                        <div>
                            Parents/caregivers are required to attend the child’s first therapist
                            consultation and all medication management sessions. Regular therapy
                            sessions do not require a parent to attend.
                        </div>
                    </div>
                </div>

                <div className={"d-md-flex pb-5"}>
                    <div className={"program-details-icon-container"}>
                        <img
                            src={images("./patient/teens_and_kids_program/audience.svg")}
                            alt={"target audience"}
                            className={"program-details-icon"}
                        />
                    </div>
                    <div className={"ml-4"}>
                        <div>
                            We are currently only accepting patients into our Teens + Kids Care
                            Program who are ages 5-17.
                        </div>
                    </div>
                </div>
            </div>

            <div className={"d-flex justify-content-center pt-3 pb-5"} data-public={"true"}>
                <Button
                    className={"Btn Btn--sec program-details-get-started-btn"}
                    onClick={() => {
                        trackEvent("Add Your Child’s Information");
                        props.nextStep();
                    }}>
                    Get Started
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    configs: state.configs,
});

export default connect(mapStateToProps)(ProgramDetails);

import React from "react";
import { RadioOld, Text, TextColors, TextTypes } from "../../../../../component-library";
import _ from "lodash";
import { getIn } from "formik";
import { CONCLUSION_RATINGS } from "./CaseManagementFormScheme";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

const CareManagementRatingField = ({
    questionTitle,
    questionName,
    formikProps,
    isViewOnly,
    testId,
}) => {
    const value = _.get(formikProps.values, questionName);
    const errorMessage = getIn(formikProps.errors, questionName);
    const isTouched = getIn(formikProps.touched, questionName);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(questionName, event.target.value);
    };

    return (
        <tr>
            <td>
                <p className="CareManagementRatingFields--question_title">{questionTitle}</p>
                {errorMessage && isTouched && (
                    <Text
                        style={{ "text-align": "left" }}
                        type={TextTypes.small}
                        color={TextColors.red}
                        layout="block">
                        {errorMessage}
                    </Text>
                )}
            </td>
            {CONCLUSION_RATINGS.map((rating) => {
                return (
                    <td>
                        <RadioOld
                            disabled={isViewOnly}
                            value={rating}
                            checked={value === rating}
                            name={questionName}
                            className={"CareManagementRatingFields--radio_button"}
                            radioClassName={"CareManagementFieldShared--radio_button"}
                            onChange={handleChange}
                            {...getAutomationDataAttr(testId)}
                        />
                    </td>
                );
            })}
        </tr>
    );
};

export const CareManagementRatingFields = ({
    title,
    subTitle,
    questions,
    formikProps,
    omitLine = false,
    isViewOnly,
    testId,
}) => {
    const content = (
        <table className="CareManagementRatingFields--table">
            <tr>
                <td className="CareManagementRatingFields--grid_header">
                    <h4 className="CareManagementFieldShared--title">{title}</h4>
                    <p className="CareManagementRatingFields--subTitle">{subTitle}</p>
                </td>
                {CONCLUSION_RATINGS.map((rating) => {
                    return (
                        <td>
                            <p className="CareManagementRatingFields--rating_title">{rating}</p>
                        </td>
                    );
                })}
            </tr>
            {questions.map((question, index) => {
                let testIdSuffix = question.name.replaceAll("_", "-");
                const lastDotIndex = question.name.lastIndexOf(".");
                if (lastDotIndex !== -1) {
                    testIdSuffix = testIdSuffix.substring(lastDotIndex + 1);
                }
                return (
                    <CareManagementRatingField
                        questionTitle={question.title}
                        questionName={question.name}
                        formikProps={formikProps}
                        isViewOnly={isViewOnly}
                        testId={`${testId}-${testIdSuffix}-field`}
                    />
                );
            })}
        </table>
    );

    if (omitLine) {
        return content;
    }

    return (
        <div className="CareManagementRatingFields--wrapper">
            <div className="CareManagementRatingFields--line" />
            {content}
        </div>
    );
};

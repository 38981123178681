// Copyright aptihealth, inc. 2021 All Rights Reserved
import React from "react";
import { connect } from "react-redux";
import ToggleButton from "react-toggle-button";

const InterestInGuardianship = (props) => {
    const { interestedInGuardianship, setInterestedInGuardianship } = props;

    return (
        <>
            <div className={"m-2"}>
                <ToggleButton
                    thumbStyle={{ height: 25, width: 25 }}
                    trackStyle={{ height: 27 }}
                    colors={{
                        activeThumb: {
                            base: "white",
                        },
                        inactiveThumb: {
                            base: "white",
                        },
                        active: {
                            base: "#5B57DC",
                        },
                        inactive: {
                            base: "#668391",
                        },
                    }}
                    inactiveLabel={""}
                    activeLabel={""}
                    value={interestedInGuardianship}
                    onToggle={(value) => setInterestedInGuardianship(!interestedInGuardianship)}
                />
            </div>
            <div className={"mx-2"}>
                <div className={"fw-bold fs-16"}>I want to register my child for care</div>
                <div className={"txt-gry fs-14"}>
                    Register your child after creating your own account. Your dashboard will have an{" "}
                    <span className={"fw-bold"}>Add Child</span> button.
                </div>
            </div>
        </>
    );
};

export const GUARDIANSHIP_PROGRAM_TYPE = {
    PARENT_CHILD: "PARENT_CHILD",
    DEFAULT: "PARENT_CHILD",
};

const mapStateToProps = (state) => ({
    configs: state.configs,
});

export default connect(mapStateToProps, null)(InterestInGuardianship);

import React from "react";
import { Text, TextArea, TextColors, TextTypes } from "../../../../../component-library";
import { getIn } from "formik";
import _ from "lodash";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

export const CareManagementField = ({ title, subTitle, formikProps, name, isViewOnly, testId }) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <div className="CareManagementFieldShared--wrapper">
            <div className="CareManagementFieldShared--text_wrapper">
                <h3 className="CareManagementFieldShared--title">{title}</h3>
                <h3 className="CareManagementFieldShared--subTitle">{subTitle}</h3>
            </div>
            <div className="CareManagementField--input_wrapper">
                {!isViewOnly && (
                    <TextArea
                        error={isTouched && errorMessage}
                        value={value}
                        className="CareManagementField--input"
                        placeholder="Type comment..."
                        onChange={handleChange}
                        ignoreEnterSubmit={true}
                        testId={testId}
                    />
                )}
                {isViewOnly && (
                    <div className="CareManagementField--view_only_input_wrapper">
                        <p
                            className="CareManagementField--view_only_input"
                            {...getAutomationDataAttr(testId)}>
                            {value === "" ? "None" : value}
                        </p>
                    </div>
                )}
                {errorMessage && isTouched && (
                    <Text type={TextTypes.small} color={TextColors.red} layout="block">
                        {errorMessage}
                    </Text>
                )}
            </div>
        </div>
    );
};

// Copyright aptihealth, inc. 2021 All Rights Reserved
import { http } from "../utils/axios";
import { buildRequestURLWithQueryParamObject } from "../utils/requestUtil";

const publicRequests = {
    update_preferences: function ({ queryParams, options }) {
        const { id } = queryParams;
        let conf = {
            method: "get",
            url: `/v1/public/update_preferences?id=${id}`,
            ...options,
        };
        return http(conf);
    },
    get_feature_flag: function ({ queryParams, options }) {
        const { feature_name } = queryParams;
        let conf = {
            method: "get",
            url: `/v1/public/feature_flag?feature_name=${feature_name}`,
            ...options,
        };
        return http(conf);
    },
    get_all_hcp_practices: function ({ options }) {
        let conf = {
            method: "post",
            url: "/v1/practice/all_hcp_practices",
            ...options,
        };
        return http(conf);
    },
    get_config: function ({ queryParams, options }) {
        const { feature_name } = queryParams;
        let conf = {
            method: "get",
            url: `/v1/public/get_config?config_name=${feature_name}`,
            ...options,
        };
        return http(conf);
    },
    get_policy: function ({ queryParams }) {
        const url = "/v1/public/get_policy";
        let conf = {
            method: "get",
            url: buildRequestURLWithQueryParamObject(url, queryParams),
        };
        return http(conf);
    },
    get_policy_metadata: function () {
        let conf = {
            method: "get",
            url: "/v1/public/get_policy_metadata",
        };
        return http(conf);
    },
};

export default publicRequests;

import React from "react";
import { Image, Text, TextColors, TextTypes } from "../../../../component-library";

import "./styles.scss";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";

export default ({ onClick }) => (
    <div
        className="AddendumLink"
        onClick={onClick}
        {...getAutomationDataAttr("note-addendum-link")}>
        <div className="AddendumLink--left">
            <Text type={TextTypes.heading2} layout="block">
                Add Addendum
            </Text>
            <Text type={TextTypes.paragraph} color={TextColors.grey} layout="block">
                Add details to an existing note.
            </Text>
        </div>
        <Image
            className="AddendumLink__icon"
            src="icons/common/chevron-right-blue.svg"
            alt="arrow"
        />
    </div>
);

// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import images from "../../../utils/images";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Button from "../../UI/Button";
import { toTitleCase } from "../../../utils/filters";
import { getCanStartCall } from "../../../utils/calendar/util";
import { getAutomationDataAttr } from "../../../utils/automationUtils";

const SheduleListItemDesktop = (props) => {
    const {
        provider_avatar,
        email,
        configs,
        callId,
        provider_name,
        provider_role,
        provider_username,
        menuClickHandler,
        scheduledDate,
        scheduledTime,
        allotted_time,
        timestamp,
        currentTime,
    } = props;
    let LocalScheduleTime = moment(scheduledTime, "HH:mm").format("hh:mm A");

    return (
        <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
            <span className="col-1 pl-4">
                <img
                    width="36"
                    src={provider_avatar || images("./common/avatar.png")}
                    alt="provider's avatar"
                />
            </span>
            <span className="col-2">{provider_name}</span>
            <span className="col-2">{toTitleCase(provider_role.replace("_", " "))}</span>
            <span className="col-2">{scheduledDate}</span>
            <span className="col-1">{LocalScheduleTime}</span>
            <Button
                onClick={() => props.history.push(`/app/video-call?callId=${callId}`)}
                className={"Btn Btn-xs fs-12 Btn--pri col-1"}
                type={"button"}
                disabled={!getCanStartCall(currentTime, timestamp, allotted_time, email, configs)}
                {...getAutomationDataAttr("member-schedule-join-now-button")}>
                Join Now
            </Button>
            <span className="col-1 pl-1">
                <img
                    role="button"
                    onClick={(e) => menuClickHandler(e, callId, provider_username)}
                    className="cursor px-2"
                    src={images("./icons/three-dots-menu.svg")}
                    alt="three dots menu"
                    {...getAutomationDataAttr("ScheduleThreeDotsMenuButton")}
                />
            </span>
        </div>
    );
};

export default withRouter(SheduleListItemDesktop);

import React, { useState } from "react";
import moment from "moment";

import { Card } from "../../../../component-library";
import AddendumDisplay from "./AddendumDisplay";
import AddendumForm from "./AddendumForm";
import AddendumLink from "./AddendumLink";
import { api } from "../../../../APIRequests";

import "./styles.scss";

export default ({
    noteId,
    patientId,
    addendum,
    signature,
    credentials,
    timestamp,
    onSubmit,
    textTestId,
}) => {
    const [isForm, setIsForm] = useState(false);

    const submitAddendum = async (addendum) => {
        const urlParams = { patientId };
        const data = {
            note_id: noteId,
            timestamp: moment().unix(),
            addendum,
            signature,
            credentials,
        };
        await api.shared.create_note_addendum({ urlParams, data });
        onSubmit(data);
    };

    return (
        <Card className="NoteAddendum">
            {!addendum && !isForm && <AddendumLink onClick={() => setIsForm(true)} />}
            {!addendum && isForm && (
                <AddendumForm
                    onSubmit={async (formData) => {
                        await submitAddendum(formData.addendum);
                        setIsForm(false);
                    }}
                    onCancel={() => setIsForm(false)}
                />
            )}
            {addendum && !isForm && (
                <AddendumDisplay
                    addendum={addendum}
                    signature={signature}
                    credentials={credentials}
                    timestamp={timestamp}
                    textTestId={textTestId}
                />
            )}
        </Card>
    );
};

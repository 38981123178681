// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../UI/Button";
import { Form } from "formik";
import * as yup from "yup";
import FormikInput from "../../UI/FormikInput";
import { api } from "../../../APIRequests";
import { withRouter } from "react-router-dom";
import { STATES } from "../../../constants/states";
import FormikInputFormat from "../../UI/formikMobileInput";
import { dobValidationSchema } from "../../../containers/Dashboard/Providers/schema/newPatientValidation";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { getQueryParams } from "../../../utils/filters";
import { trackEvent } from "../../../utils/EventTrackingUtil";
import moment from "moment-timezone";
import { CustomForm } from "../../../component-library/CustomForm";

const ReleaseOfInformation = (props) => {
    const { nextStep, patientMetaData, providerMetaData } = props;
    const patientId = getQueryParams("patientId", props.location.search);
    const [initialFormValues, setInitialFormValues] = useState(cloneDeep(initialValues));

    async function getPatient() {
        let patient;
        if (!patientMetaData) {
            const headers = {
                "X-On-Behalf-Of": patientId,
                "X-On-Behalf-Of-Entity-Type": "patient",
            };
            patient = await api.patient.get_patient_meta_data({ headers });
        } else {
            patient = patientMetaData;
        }
        return patient;
    }

    async function getProvider() {
        let provider;
        if (!providerMetaData) {
            const options = { params: { showLoader: true } };
            provider = await api.patient.get_provider_meta_data(options);
        } else {
            provider = providerMetaData;
        }
        return provider;
    }

    useEffect(() => {
        (async () => {
            const patient = await getPatient();

            const newFormValues = { ...initialFormValues };
            newFormValues.patient.name = patient.first_name + " " + patient.last_name;
            newFormValues.patient.address = patient.address;
            newFormValues.patient.dob = patient.dob.replaceAll("/", "");

            if (patient.pcp_id === props.profile.pcp_id) {
                const provider = await getProvider();
                newFormValues.medical_record_sources.pcp.name = provider.name;
                newFormValues.medical_record_sources.pcp.address = provider.address;
                newFormValues.medical_record_sources.pcp.phone = provider.phone_number;
                newFormValues.medical_record_sources.pcp.fax = provider.fax_number;
                newFormValues.medical_record_sources.source_type_pcp = true;
            }

            newFormValues.signature.date = moment().format("MMDDYYYY");

            setInitialFormValues(newFormValues);
        })();
    }, []);

    const submit = async (formData, actions) => {
        try {
            const data = {
                file_type: "RELEASE_OF_INFORMATION_V1",
                content: { ...formData },
            };

            const headers = {
                "X-On-Behalf-Of": patientId,
                "X-On-Behalf-Of-Entity-Type": "patient",
            };
            trackEvent("Schedule A Therapist Consultation");
            await api.shared.start_file_generation({ data, headers });

            nextStep();
        } catch (e) {
            console.log(e);
            nextStep();
        }
    };

    const medicalRecordsOnChange = async (formikProps) => {
        await formikProps.setFieldValue("medical_record_types.record_type", "specific_types");
    };

    function patientInfoSection(formikInputProps, formikProps) {
        return (
            <div className={"row"}>
                <div className={"col-lg-6"}>
                    <FormikInput
                        disabled
                        {...formikInputProps}
                        formEl={{
                            name: "patient.name",
                            elementType: "input",
                            elementConfig: {
                                label: "Patient Name",
                                placeholder: "Enter Name",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInputFormat
                        disabled
                        {...formikInputProps}
                        formatType={"MM/DD/YYYY"}
                        value={formikProps && formikProps.values.patient.dob}
                        onChange={async (val) => {
                            await formikProps.setFieldValue("patient.dob", val.value);
                            formikProps.setFieldTouched("patient.dob");
                        }}
                        formEl={{
                            name: "patient.dob",
                            elementType: "input",
                            elementConfig: {
                                label: "Date of Birth",
                                placeholder: "MM/DD/YYYY",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInput
                        disabled
                        {...formikInputProps}
                        formEl={{
                            name: "patient.address.street",
                            elementType: "input",
                            elementConfig: {
                                label: "Address",
                                placeholder: "Enter Address",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInput
                        disabled
                        {...formikInputProps}
                        formEl={{
                            name: "patient.address.city",
                            elementType: "input",
                            elementConfig: {
                                label: "City",
                                placeholder: "Enter City",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInput
                        disabled
                        {...formikInputProps}
                        inputClasses={"Input--sm-dropdown"}
                        formEl={{
                            name: "patient.address.state",
                            elementType: "select",
                            elementConfig: {
                                options: STATES,
                                label: "State",
                                placeholder: "Enter State",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInput
                        disabled
                        {...formikInputProps}
                        formEl={{
                            name: "patient.address.zip_code",
                            elementType: "input",
                            elementConfig: {
                                label: "Zip",
                                placeholder: "Enter Zip",
                            },
                        }}
                    />
                </div>
            </div>
        );
    }

    function medicalRecordsSource(formikInputProps, formikProps) {
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"fw-bold my-3"}>
                        I, or my authorized representative, authorize aptihealth to obtain/release
                        medical records from the following:
                        {formikProps.errors.medical_record_sources === "Required" && (
                            <span className={"txt-error fw-normal fs-14"}> Required</span>
                        )}
                    </div>

                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "medical_record_sources.source_type_pcp",
                            elementType: "input",
                            elementConfig: {
                                inputClasses: "fw-bold",
                                type: "checkbox",
                                hideTopLabel: true,
                                options: [
                                    {
                                        value: "source_type_pcp",
                                        display: "Child’s Primary Care Provider",
                                        optionClasses: "col-12 py-1",
                                        checked:
                                            formikProps.values.medical_record_sources
                                                .source_type_pcp,
                                    },
                                ],
                            },
                            onclick: async (field, option) => {
                                const newValue =
                                    !formikProps.values.medical_record_sources.source_type_pcp;
                                await formikProps.setFieldValue(
                                    "medical_record_sources.source_type_pcp",
                                    newValue,
                                );
                                formikProps.setFieldTouched(
                                    "medical_record_sources.source_type_pcp",
                                );
                                option.checked = newValue;
                            },
                        }}
                    />
                </div>

                <div className={"ml-4 mr-2 row"}>
                    <div className={"col-12"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "medical_record_sources.pcp.name",
                                elementType: "input",
                                elementConfig: {
                                    hideTopLabel: true,
                                    placeholder: "Enter Primary Care Physician",
                                },
                            }}
                        />
                    </div>

                    <div className={"col-lg-6"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "medical_record_sources.pcp.address.street",
                                elementType: "input",
                                elementConfig: {
                                    label: "Address",
                                    placeholder: "Enter Address",
                                },
                            }}
                        />
                    </div>

                    <div className={"col-lg-6"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "medical_record_sources.pcp.address.city",
                                elementType: "input",
                                elementConfig: {
                                    label: "City",
                                    placeholder: "Enter City",
                                },
                            }}
                        />
                    </div>

                    <div className={"col-lg-6"}>
                        <FormikInput
                            {...formikInputProps}
                            inputClasses={"Input--sm-dropdown"}
                            formEl={{
                                name: "medical_record_sources.pcp.address.state",
                                elementType: "select",
                                elementConfig: {
                                    options: STATES,
                                    label: "State",
                                    placeholder: "Enter State",
                                },
                            }}
                        />
                    </div>

                    <div className={"col-lg-6"}>
                        <FormikInput
                            {...formikInputProps}
                            formEl={{
                                name: "medical_record_sources.pcp.address.zip_code",
                                elementType: "input",
                                elementConfig: {
                                    label: "Zip",
                                    placeholder: "Enter Zip",
                                },
                            }}
                        />
                    </div>

                    <div className={"col-lg-6"}>
                        <FormikInputFormat
                            {...formikInputProps}
                            value={
                                formikProps && formikProps.values.medical_record_sources.pcp.phone
                            }
                            onChange={async (val) => {
                                await formikProps.setFieldValue(
                                    "medical_record_sources.pcp.phone",
                                    val.value,
                                );
                                formikProps.setFieldTouched("medical_record_sources.pcp.phone");
                            }}
                            formEl={{
                                name: "medical_record_sources.pcp.phone",
                                elementType: "input",
                                elementConfig: {
                                    label: "Phone",
                                    placeholder: "Enter Phone",
                                },
                            }}
                        />
                    </div>

                    <div className={"col-lg-6"}>
                        <FormikInputFormat
                            {...formikInputProps}
                            value={formikProps && formikProps.values.medical_record_sources.pcp.fax}
                            onChange={async (val) => {
                                await formikProps.setFieldValue(
                                    "medical_record_sources.pcp.fax",
                                    val.value,
                                );
                                formikProps.setFieldTouched("medical_record_sources.pcp.fax");
                            }}
                            formEl={{
                                name: "medical_record_sources.pcp.fax",
                                elementType: "input",
                                elementConfig: {
                                    label: "Fax",
                                    placeholder: "Enter Fax",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className={"col-12"}>
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "medical_record_sources.source_type_all",
                            elementType: "input",
                            elementConfig: {
                                inputClasses: "fw-bold",
                                type: "checkbox",
                                hideTopLabel: true,
                                options: [
                                    {
                                        value: "source_type_all",
                                        display:
                                            "Health care providers (on an ongoing basis) for the duration of treatment by aptihealth",
                                        optionClasses: "col-12 py-1",
                                        checked:
                                            formikProps.values.medical_record_sources
                                                .source_type_all,
                                    },
                                ],
                            },
                            onclick: async (field, option) => {
                                const newValue =
                                    !formikProps.values.medical_record_sources.source_type_all;
                                await formikProps.setFieldValue(
                                    "medical_record_sources.source_type_all",
                                    newValue,
                                );
                                formikProps.setFieldTouched(
                                    "medical_record_sources.source_type_all",
                                );
                                option.checked = newValue;
                            },
                        }}
                    />
                </div>
            </div>
        );
    }

    function dateRange(formikInputProps, formikProps) {
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"fw-bold  my-3"}>
                        For treatment date(s):
                        <span className={"txt-rose fw-normal"}> (select one)</span>
                        {formikProps.touched.date_range &&
                            formikProps.touched.date_range.range_type &&
                            formikProps.errors.date_range &&
                            formikProps.errors.date_range.range_type === "Required" && (
                                <span className={"txt-error fw-normal fs-14"}> Required</span>
                            )}
                    </div>

                    <FormikInput
                        {...formikInputProps}
                        errorClasses={"d-none"}
                        formEl={{
                            name: "date_range.range_type",
                            elementType: "radio-single",
                            elementConfig: {
                                hideTopLabel: true,
                                inputClasses: "fw-bold",
                                label: "Any/all previous treatment dates at your facility",
                                value: "all",
                                id: "date_range.range_type.all",
                                checked: formikProps.values.date_range.range_type === "all",
                            },
                            onchange: async (source_type, val) => {
                                await formikProps.setFieldValue(source_type, val);
                                formikProps.setFieldTouched(source_type);
                            },
                        }}
                    />
                </div>

                <div className={"col-12"}>
                    <div className={"d-flex"}>
                        <FormikInput
                            {...formikInputProps}
                            errorClasses={"d-none"}
                            formEl={{
                                name: "date_range.range_type",
                                elementType: "radio-single",
                                elementConfig: {
                                    hideTopLabel: true,
                                    label: "",
                                    value: "specific_range",
                                    id: "date_range.range_type.specific_range",
                                    checked:
                                        formikProps.values.date_range.range_type ===
                                        "specific_range",
                                },
                                onchange: async (source_type, val) => {
                                    await formikProps.setFieldValue(source_type, val);
                                    formikProps.setFieldTouched(source_type);
                                },
                            }}
                        />

                        <FormikInputFormat
                            {...formikInputProps}
                            formatType={"MM/DD/YYYY"}
                            value={
                                formikProps &&
                                formikProps.values.date_range.specific_range.start_date
                            }
                            onChange={async (val) => {
                                await formikProps.setFieldValue(
                                    "date_range.specific_range.start_date",
                                    val.value,
                                );
                                formikProps.setFieldTouched("date_range.specific_range.start_date");
                            }}
                            formEl={{
                                name: "date_range.specific_range.start_date",
                                elementType: "input",
                                elementConfig: {
                                    hideTopLabel: true,
                                    placeholder: "MM/DD/YYYY",
                                },
                            }}
                        />

                        <div className={"fw-bold mt-2 mx-md-3 mx-1"}>To</div>

                        <FormikInputFormat
                            formatType={"MM/DD/YYYY"}
                            {...formikInputProps}
                            value={
                                formikProps && formikProps.values.date_range.specific_range.end_date
                            }
                            onChange={async (val) => {
                                await formikProps.setFieldValue(
                                    "date_range.specific_range.end_date",
                                    val.value,
                                );
                                formikProps.setFieldTouched("date_range.specific_range.end_date");
                            }}
                            formEl={{
                                name: "date_range.specific_range.end_date",
                                elementType: "input",
                                elementConfig: {
                                    hideTopLabel: true,
                                    placeholder: "MM/DD/YYYY",
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function medicalRecordTypes(formikInputProps, formikProps) {
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"fw-bold my-3"}>
                        Medical records includes:
                        <span className={"txt-rose fw-normal"}> (select one)</span>
                        {formikProps.touched.medical_record_types &&
                            formikProps.touched.medical_record_types.record_type &&
                            formikProps.errors.medical_record_types &&
                            formikProps.errors.medical_record_types.record_type === "Required" && (
                                <span className={"txt-error fw-normal fs-14"}> Required</span>
                            )}
                    </div>

                    <FormikInput
                        {...formikInputProps}
                        errorClasses={"d-none"}
                        formEl={{
                            name: "medical_record_types.record_type",
                            elementType: "radio-single",
                            elementConfig: {
                                inputClasses: "fw-bold",
                                hideTopLabel: true,
                                label: "All records regarding my treatment, hospitalization, and outpatient care. A separate authorization is required for the use or disclosure of psychotherapy notes or research health information.",
                                value: "all",
                                id: "medical_record_types.record_type.all",
                                checked:
                                    formikProps.values.medical_record_types.record_type === "all",
                            },
                            onchange: async (source_type, val) => {
                                await formikProps.setFieldValue(
                                    "medical_record_types.specific_types",
                                    {
                                        admission_summary: false,
                                        drug_treatment: false,
                                        medication_history: false,
                                        discharge_summary: false,
                                        history_and_physical: false,
                                        progress_notes: false,
                                        psychological_testing: false,
                                        lab_reports: false,
                                        iep: false,
                                        other: false,
                                        other_text: "",
                                    },
                                );
                                await formikProps.setFieldValue(source_type, val);
                                formikProps.setFieldTouched(source_type);
                            },
                        }}
                    />
                </div>

                <div className={"col-12"}>
                    <FormikInput
                        {...formikInputProps}
                        errorClasses={"d-none"}
                        formEl={{
                            name: "medical_record_types.record_type",
                            elementType: "radio-single",
                            elementConfig: {
                                hideTopLabel: true,
                                inputClasses: "fw-bold",
                                label: (
                                    <>
                                        The following records, specific types of health information,
                                        or records for the date(s) of treatment as specified:
                                        {formikProps.touched.medical_record_types &&
                                            formikProps.touched.medical_record_types
                                                .specific_types &&
                                            formikProps.errors.medical_record_types &&
                                            formikProps.errors.medical_record_types
                                                .specific_types === "Required" && (
                                                <span className={"txt-error fw-normal fs-14"}>
                                                    {" "}
                                                    Required
                                                </span>
                                            )}
                                    </>
                                ),
                                value: "specific_types",
                                id: "medical_record_types.record_type.specific_types",
                                checked:
                                    formikProps.values.medical_record_types.record_type ===
                                    "specific_types",
                            },
                            onchange: async (source_type, val) => {
                                await formikProps.setFieldValue(source_type, val);
                                formikProps.setFieldTouched(source_type);
                            },
                        }}
                    />
                </div>

                <div className={"row ml-5 mr-3"}>
                    <div className={"col-12"}>
                        <FormikInput
                            {...formikInputProps}
                            errorClasses={"d-none"}
                            formEl={{
                                name: "medical_record_types.specific_types",
                                elementType: "input",
                                elementClasses: "col-12",
                                errorClasses: "InputError--lg-2",
                                elementConfig: {
                                    hideTopLabel: true,
                                    type: "checkbox",
                                    optionClasses: "col-lg-4 col-12 py-1",
                                    options: [
                                        {
                                            value: "admission_summary",
                                            display: "Admission Summary",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.admission_summary,
                                        },
                                        {
                                            value: "drug_treatment",
                                            display: "Drug Treatment",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.drug_treatment,
                                        },
                                        {
                                            value: "medication_history",
                                            display: "Medication History",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.medication_history,
                                        },
                                        {
                                            value: "discharge_summary",
                                            display: "Discharge Summary",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.discharge_summary,
                                        },
                                        {
                                            value: "history_and_physical",
                                            display: "History and Physical",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.history_and_physical,
                                        },
                                        {
                                            value: "progress_notes",
                                            display: "Progress Notes",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.progress_notes,
                                        },
                                        {
                                            value: "psychological_testing",
                                            display: "Psychological Testing",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.psychological_testing,
                                        },
                                        {
                                            value: "lab_reports",
                                            display: "Lab Reports",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.lab_reports,
                                        },
                                        {
                                            value: "iep",
                                            display: "IEP",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.iep,
                                        },
                                        {
                                            value: "other",
                                            display: "Other",
                                            openText: true,
                                            optionClasses: "col-12 py-1",
                                            checked:
                                                formikProps.values.medical_record_types
                                                    .specific_types.other,
                                            onChange: async (e) => {
                                                const val = e.target.value;
                                                await formikProps.setFieldValue(
                                                    "medical_record_types.specific_types.other_text",
                                                    val,
                                                );
                                            },
                                        },
                                    ],
                                },
                                onclick: async (field, option) => {
                                    await medicalRecordsOnChange(formikProps);
                                    const newValue =
                                        !formikProps.values.medical_record_types.specific_types[
                                            option.value
                                        ];
                                    await formikProps.setFieldValue(
                                        `${field}.${option.value}`,
                                        newValue,
                                    );
                                    formikProps.setFieldTouched(`${field}.${option.value}`);
                                    option.checked = newValue;
                                },
                            }}
                        />
                    </div>

                    <div className={"fw-bold mb-4"}>
                        I expressly consent to the disclosure of
                        <span>
                            {" "}
                            <u>all of my medical records indicated above.</u>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    function purpose(formikInputProps, formikProps) {
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"fw-bold my-3"}>
                        Purpose: The purpose and limitations (if any) of the requested use or
                        disclosure is:
                        <span className={"txt-rose fw-normal"}> (select one)</span>
                        {formikProps.touched.purpose &&
                            formikProps.touched.purpose.purpose_type &&
                            formikProps.errors.purpose &&
                            formikProps.errors.purpose.purpose_type === "Required" && (
                                <span className={"txt-error fw-normal fs-14"}> Required</span>
                            )}
                    </div>

                    <FormikInput
                        {...formikInputProps}
                        errorClasses={"d-none"}
                        formEl={{
                            name: "purpose.purpose_type",
                            elementType: "radio-single",
                            elementConfig: {
                                hideTopLabel: true,
                                inputClasses: "fw-bold",
                                label: "For treatment purposes and/or collaboration of care; OR",
                                value: "all",
                                id: "purpose.purpose_type.all",
                                checked: formikProps.values.purpose.purpose_type === "all",
                            },
                            onchange: async (source_type, val) => {
                                await formikProps.setFieldValue(source_type, val);
                                formikProps.setFieldTouched(source_type);
                            },
                        }}
                    />
                </div>

                <div className={"col-12"}>
                    <FormikInput
                        {...formikInputProps}
                        errorClasses={"d-none"}
                        formEl={{
                            name: "purpose.purpose_type",
                            elementType: "radio-single",
                            elementConfig: {
                                hideTopLabel: true,
                                label: "Other",
                                value: "other",
                                id: "purpose.purpose_type.other",
                                checked: formikProps.values.purpose.purpose_type === "other",
                                openText: true,
                                onChange: async (e) => {
                                    const val = e.target.value;
                                    await formikProps.setFieldValue("purpose.other_text", val);
                                },
                            },
                            onchange: async (source_type, val) => {
                                await formikProps.setFieldValue(source_type, val);
                                formikProps.setFieldTouched(source_type);
                            },
                        }}
                    />
                </div>
            </div>
        );
    }

    function disclaimer() {
        return (
            <div className={"row my-4"}>
                <div className={"col-12"}>
                    <div className={"fw-bold mb-2"}>
                        This authorization ends <u>one year from the date of signature below.</u>
                    </div>

                    <div className={"fw-bold mb-2"}>
                        In accordance with applicable state law and the Health Insurance Portability
                        and Accountability Act of 1996 (HIPAA) Privacy Standards, I understand and
                        agree that:
                    </div>

                    <div className={"mb-2"}>
                        This authorization may include information relating to alcohol and drug
                        abuse, mental health treatment, psychotherapy notes) and confidential HIV
                        related information. In the event the health information described above
                        includes any of these types of information, I specifically authorize the
                        release of such information to the recipient identified above. I have the
                        right to revoke this authorization, in writing, at any time, except where
                        action has been taken based upon my authorization. To revoke this
                        authorization, I must do so in writing and send it to the appropriate
                        disclosing party.
                    </div>

                    <div className={"mb-2"}>
                        It is possible that information used or disclosed with my permission may be
                        re-disclosed by the recipient and is no longer protected by the HIPAA
                        Privacy Standards or state law. If I am authorizing the release of alcohol
                        or drug treatment, mental health treatment, psychotherapy notes, or HIV
                        related information, the recipient is prohibited from re­disclosing such
                        information or using the disclosed information for any other purpose without
                        my authorization, unless permitted to do so under federal or state law.
                    </div>

                    <div className={"mb-2"}>
                        Signing this authorization is voluntary. My treatment by any party, payment,
                        enrollment in a health plan, or eligibility for benefits may not be
                        conditioned upon my signing of this authorization (unless treatment is
                        sought only to create health information for a third party or to take part
                        in a research study).
                    </div>
                </div>
            </div>
        );
    }

    function signature(formikInputProps, formikProps) {
        return (
            <div className={"row"}>
                <div className={"col-lg-10"}>
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "signature.representative_signature",
                            elementType: "input",
                            elementConfig: {
                                label: (
                                    <>
                                        Patient/Representative Signature{" "}
                                        <span className={"txt-rose fw-normal"}>(required)</span>
                                    </>
                                ),
                                placeholder: "Signature",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-2"}>
                    <FormikInputFormat
                        disabled
                        {...formikInputProps}
                        formatType={"MM/DD/YYYY"}
                        value={formikProps && formikProps.values.signature.date}
                        onChange={async (val) => {
                            await formikProps.setFieldValue("signature.date", val.value);
                            formikProps.setFieldTouched("signature.date");
                        }}
                        formEl={{
                            name: "signature.date",
                            elementType: "input",
                            elementConfig: {
                                label: (
                                    <>
                                        Date{" "}
                                        <span className={"txt-rose fw-normal"}>(required)</span>
                                    </>
                                ),
                                placeholder: "MM/DD/YYYY",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "signature.name_of_person_signing",
                            elementType: "input",
                            elementConfig: {
                                label: (
                                    <>
                                        If not patient, name of person signing{" "}
                                        <span className={"txt-rose fw-normal"}>(required)</span>
                                    </>
                                ),
                                placeholder: "Enter Name",
                            },
                        }}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <FormikInput
                        {...formikInputProps}
                        formEl={{
                            name: "signature.relationship_to_sign_for_patient",
                            elementType: "input",
                            elementConfig: {
                                label: (
                                    <>
                                        Relationship or authority to sign for patient{" "}
                                        <span className={"txt-rose fw-normal"}>(required)</span>
                                    </>
                                ),
                                placeholder: "Enter Relationship",
                            },
                        }}
                    />
                </div>
            </div>
        );
    }

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
            inputClasses: "Input--sm",
        };

        return (
            <Form>
                <div className={"fw-bold fs-18 mb-4"}>
                    HIPAA Authorization for Use or Disclosure of Health Information
                </div>

                <div className={"mb-3"}>{patientInfoSection(formikInputProps, formikProps)}</div>

                <div className={"mb-3"}>{medicalRecordsSource(formikInputProps, formikProps)}</div>

                <div className={"mb-3"}>{dateRange(formikInputProps, formikProps)}</div>

                <div className={"mb-3"}>{medicalRecordTypes(formikInputProps, formikProps)}</div>

                <div className={"mb-3"}>{purpose(formikInputProps, formikProps)}</div>

                <div className={"mb-3"}>{disclaimer()}</div>

                <div className={"mb-3"}>{signature(formikInputProps, formikProps)}</div>

                <div className={"d-flex justify-content-end pb-4"}>
                    <Button type={"submit"} className={"Btn Btn--pri"}>
                        Continue
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className={"txt-sec fw-bold pt-2 mx-3 workflow-step-title"}>
                Step 2 of 3: Release of Information
            </div>

            <div className={"mx-4 my-3"}>
                <CustomForm
                    initialValues={initialFormValues}
                    validationSchema={validation}
                    onSubmit={submit}
                    render={renderForm}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps, {})(withRouter(ReleaseOfInformation));

const validation = yup.object().shape({
    patient: yup.object().shape({
        name: yup.string().required("Required"),
        dob: dobValidationSchema.required("Required"),
        address: yup.object().shape({
            street: yup.string().required("Required"),
            city: yup.string().required("Required"),
            state: yup.string().required("Required"),
            zip_code: yup.string().required("Required"),
        }),
    }),
    medical_record_sources: yup
        .object()
        .test(
            "check_at_least_one_true",
            "Required",
            (obj) => obj.source_type_all || obj.source_type_pcp,
        )
        .shape({
            pcp: yup.object().when("source_type_pcp", {
                is: (actualValue) => actualValue,
                then: yup.object().shape({
                    name: yup.string().required("Required"),
                    phone: yup.string().required("Required"),
                    fax: yup.string().required("Required"),
                    address: yup.object().shape({
                        street: yup.string().required("Required"),
                        city: yup.string().required("Required"),
                        state: yup.string().required("Required"),
                        zip_code: yup.string().required("Required"),
                    }),
                }),
            }),
        }),
    date_range: yup.object().shape({
        range_type: yup.string().required("Required"),
        specific_range: yup.object().when("range_type", {
            is: (actualValue) => actualValue === "specific_range",
            then: yup.object().shape({
                start_date: dobValidationSchema.required("Required"),
                end_date: dobValidationSchema.required("Required"),
            }),
        }),
    }),
    medical_record_types: yup.object().shape({
        record_type: yup.string().required("Required"),
        specific_types: yup.object().when("record_type", {
            is: (actualValue) => actualValue === "specific_types",
            then: yup.object().test("check_at_least_one_true", "Required", (obj) => {
                return Object.values(obj).some((isChecked) => isChecked);
            }),
        }),
    }),
    purpose: yup.object().shape({
        purpose_type: yup.string().required("Required"),
    }),
    signature: yup.object().shape({
        representative_signature: yup.string().required("Required"),
        date: yup.string().required("Required"),
        name_of_person_signing: yup.string().required("Required"),
        relationship_to_sign_for_patient: yup.string().required("Required"),
    }),
});

const initialValues = {
    patient: {
        name: "",
        dob: "",
        address: {
            street: "",
            city: "",
            state: "",
            zip_code: "",
        },
    },
    medical_record_sources: {
        source_type_all: true,
        source_type_pcp: false,
        pcp: {
            name: "",
            address: {
                street: "",
                city: "",
                state: "",
                zip_code: "",
            },
            phone: "",
            fax: "",
        },
    },
    date_range: {
        range_type: "", // specific_range || all
        specific_range: {
            start_date: "",
            end_date: "",
        },
    },
    medical_record_types: {
        record_type: "", // specific_types || all
        specific_types: {
            admission_summary: false,
            drug_treatment: false,
            medication_history: false,
            discharge_summary: false,
            history_and_physical: false,
            progress_notes: false,
            psychological_testing: false,
            lab_reports: false,
            iep: false,
            other: false,
            other_text: "",
        },
    },
    purpose: {
        purpose_type: "", // all || other
        other_text: "",
    },
    signature: {
        representative_signature: "",
        date: "",
        name_of_person_signing: "",
        relationship_to_sign_for_patient: "",
    },
};
